import React from "react";
import "./ChooseUs.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
const ChooseUs = () => {
  const cardsDetails = [
    {
      title: "Free door-to-door shipping",
      text: "With a short period of time",
      image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/truck.png",
      Baselink: "/shippingpolicy",
    },
    {
      title: "24/7 Support",
      text: "Ready to pickup calls",
      image:
        process.env.PUBLIC_URL +
        "/assests/HomePage/ChooseUs/technical-support.png",
      Baselink: "/contact-us",
    },
    {
      title: "Secure transaction",
      text: "Checkout securely",
      image:
        process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/locked-card.png",
      Baselink: "/payment-method",
    },
    {
      title: "B2B Bulk Order Available",
      text: "On many products",
      image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/booking.png",
      Baselink: "/b2bbulkorder",
    },
    {
      title: "Free door-to-door shipping",
      text: "With a short period of time",
      image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/truck.png",
      Baselink: "/shippingpolicy",
    },
    {
      title: "24/7 Support",
      text: "Ready to pickup calls",
      image:
        process.env.PUBLIC_URL +
        "/assests/HomePage/ChooseUs/technical-support.png",
      Baselink: "/contact-us",
    },
    {
      title: "Secure transaction",
      text: "Checkout securely",
      image:
        process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/locked-card.png",
      Baselink: "/payment-method",
    },
    {
      title: "B2B Bulk Order Available",
      text: "On many products",
      image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/booking.png",
      Baselink: "/b2bbulkorder",
    },
  ];
  return (
    <>
      <section className="ChooseUs">
        <Container>
          <div className="chooseus_main_div">
            <Row>
              <Col xxl={3} xl={3} lg={3}>
                <h1 className="choose merriweather-bold">
                  Choose <br></br>Us!
                </h1>
              </Col>
              <Col xxl={9} xl={9} lg={9}>
                <Swiper
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    486: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1400: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1900: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                  }}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {cardsDetails.map((item, index) => (
                    <SwiperSlide>
                      <Link to={item.Baselink}>
                        <div className="main">
                        <div class="Step-choose-container">
                          <div className="Step-choose">
                            <h4 className="carddheading">{item.title}</h4>
                            <p className="carddtext sub-text">{item.text}</p>
                            <div className="borderciclee"></div>
                          </div>
                          </div>
                          <div className="circles">
                            <img className="circle11" src={item.image} />
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ChooseUs;
