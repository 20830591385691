import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./WhyChoose.css";
const WhyChoose = () => {
    const choose = [
        {
            image: process.env.PUBLIC_URL + "/assests/Cmj/c1.png",
            no: "01",
            text: "100 % customer satisfation",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Cmj/c2.png",
            no: "02",
            text: "100% Secure Payment",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Cmj/c3.png",
            no: "03",
            text: "Easy to contact us",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Cmj/c4.png",
            no: "04",
            text: "Best Prices",
        },
    ];
    return (
        <>
            <section className="WhyChoose">
                <Container>
                    <h1 className="title-main">
                        <span className="main-title-border">WHY CHOOSE</span> US?
                    </h1>
                    <Row className="choose-Main">
                        {/* {choose.map((item, index) => ( */}
                            <Col xxl={3} xl={3} lg={6} md={6} sm={6} xs={12}>
                                <div className="Choose-card" >
                                    <h1 className="no">01</h1>
                                    <div className="Main-img">
                                        <div className="image">
                                            <img className="choose1" src={process.env.PUBLIC_URL + "/assests/Cmj/c1.png"} />
                                        </div>
                                        <p className="commmontext">100 % customer<br></br> satisfation</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={6} md={6} sm={6} xs={12}>
                                <div className="Choose-card" >
                                    <h1 className="no">02</h1>
                                    <div className="Main-img">
                                        <div className="image2">
                                            <img className="choose1" src={process.env.PUBLIC_URL + "/assests/Cmj/c2.png"} />
                                        </div>
                                        <p className="commmontext">100% Secure<br></br> Payment</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={6} md={6} sm={6} xs={12}>
                                <div className="Choose-card" >
                                    <h1 className="no">03</h1>
                                    <div className="Main-img">
                                        <div className="image3">
                                            <img className="choose1" src={process.env.PUBLIC_URL + "/assests/Cmj/c3.png"} />
                                        </div>
                                        <p className="commmontext">Easy to <br></br>contact us</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={6} md={6} sm={6} xs={12}>
                                <div className="Choose-card" >
                                    <h1 className="no">04</h1>
                                    <div className="Main-img">
                                        <div className="image4">
                                            <img className="choose1" src={process.env.PUBLIC_URL + "/assests/Cmj/c4.png"} />
                                        </div>
                                        <p className="commmontext">Best Prices</p>
                                    </div>
                                </div>
                            </Col>
                        {/* ))} */}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default WhyChoose;
