import React, { useEffect, useState } from "react";
import "./CompareList.css";
import { Col, Container, Row } from "react-bootstrap";
import ProductCardSale from "../CommonComponent/ProductCardSale/ProductCardSale";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import ProductCard from "../CommonComponent/ProductCard/ProductCard";
import { Link } from "react-router-dom";
function CompareList() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Account", link: "" },
        { text: "Compared List", link: "" },
        ,
      ]);

  const product = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
  ];

  return (
    <>
      <section className="comparelist_section">
        <Container>
          <div className="comparelist_main">
            <div>
            <Breadcrumbs items={breadcrumbItems} />
            </div>
            <div>
              <Row>
                {product.map((item, index) => (
                  <Col xxl={3} xl={3} lg={4} md={6} sm={12} className="mb-3">
                    <div>
                      <ProductCard
                        image={item.image}
                        title={item.title}
                        size={item.size}
                        saleprice={item.saleprice}
                        realprice={item.realprice}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div>
              <Link to="/"><p className="text-p text-decoration-underline text-center mt-3">Continue Shopping</p></Link>  
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default CompareList;
