import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import Modal from "react-bootstrap/Modal";
import "./SelectOption.css";
import Button_Common from "../../Button_Common/Button_Common";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
const SelectOption = (props) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <section className="SelectOption">
                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="SelectOption"
                >
                    {/* <Modal.Body> */}
                    <div className="Modal-holder">
                        <div className="log_in_form">
                            <div className="login-box-button">
                                <p>Select a reason</p>
                            </div>
                            <div className="select_reason">
                                <Form>
                                    <Form.Check
                                        label="Product price has reduced"
                                        name="reason"
                                        type="radio"
                                        className="small-text"
                                    />
                                    <Form.Check
                                        label="Wrong contact number entered"
                                        name="reason"
                                        type="radio"
                                        className="small-text"
                                    />

                                    <Form.Check
                                        label="Incorrect product size/colour/type ordered"
                                        name="reason"
                                        type="radio"
                                        className="small-text"
                                    />
                                    <Form.Check
                                        label="Purchased product from somewhere else"
                                        name="reason"
                                        type="radio"
                                        className="small-text"
                                    />
                                    <Form.Check
                                        label="Wrong address selected"
                                        name="reason"
                                        type="radio"
                                        className="small-text"
                                    />
                                    <Form.Check
                                        label="Product not required anymore"
                                        name="reason"
                                        type="radio"
                                        className="small-text"
                                    />
                                    <Form.Check
                                        label="Incorrect payment method selected"
                                        name="reason"
                                        type="radio"
                                        className="small-text"
                                    />
                                    <Form.Check
                                        label="Expected delivery time is too long"
                                        name="reason"
                                        type="radio"
                                        className="small-text"
                                    />
                                    <Form.Check label="Other" name="reason" type="radio" className="small-text" />
                                </Form>
                                <div className="Submit-select">
                                    <Button_Common
                                        onClick={() => {
                                            setModalShow(true);
                                            props.onHide();
                                        }}
                                        text="Submit"
                                        className="submit orange small-text"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Modal.Body> */}
                </Modal>
            </section>

            {/* <Successfull_Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message="Reason Sumbited successful!"
            /> */}
        </>
    );
};

export default SelectOption;
