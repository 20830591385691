import React, {useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Breadcrumbs from "../../CommonComponent/Breadcrumbs/Breadcrumbs";
import Dropdown from "react-bootstrap/Dropdown";
import "./ProductSlider.css";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Form from "react-bootstrap/Form";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCartPlus,
    faLock,
    faAngleUp,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faBox,
    faHandHoldingHeart,
    faTruck,
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import { Rating } from "react-simple-star-rating";

const ProductSlider = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "Transparent Tape", link: "/b2bbulkorder" , className: "active"},
       
    ]);
    const [selectedOption, setSelectedOption] = useState("Transparent Tape");

    const handleSelect = (eventKey) => {
        setSelectedOption(eventKey);
    };

    const options = [
        {key: "Transparent Tape", label: "Transparent Tape"},
        {key: "Brown Tape", label: "Brown Tape"},
    ];

    const visibleOptions = options.filter((option) => option.key !== selectedOption);
    const [modalShowone, setModalShowone] = React.useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const colorsd = ["#000", "#33FF57", "#3357FF", "#F333FF"];

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    const [deliveryChecked, setDeliveryChecked] = useState(false);

    const handleCheckDelivery = () => {
        setDeliveryChecked(true);
    };
    const slidesData = [
        {
            image: "/assests/ProductDetails/p1.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"),
        },
        {
            image: "/assests/ProductDetails/p1.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"),
        },
        {
            image: "/assests/ProductDetails/p1.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"),
        },
        {
            image: "/assests/ProductDetails/p1.png",
            onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"),
        },
        // {
        //     image: "/assests/ProductDetails/p1.png",
        //     onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"),
        // },
        // {
        //     image: "/assests/ProductDetails/p1.png",
        //     onClick: () => handleImageClick(process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"),
        // },
    ];
    const [number, setNumber] = useState(1);
    const handleMinusClick = () => {
        if (number > 1) {
            setNumber(number - 1);
        }
    };

    const handlePlusClick = () => {
        setNumber(number + 1);
    };

    const [showButtons, setShowButtons] = useState(false);
    const [show, setShow] = useState(false);

    const [heartactive, setheartActive] = useState(false);
    const handleClickes = ({data}) => {
        setheartActive(!heartactive);
    };
    return (
        <>
            <section className="ProductSlider">
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6}>
                            <Breadcrumbs items={breadcrumbItems} />
                        </Col>
                        <Col xxl={6} xl={6} lg={6}>
                            <div className="drop">
                                <p className="text-p">Category</p>
                                <Dropdown onSelect={handleSelect}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="text-p">
                                        {selectedOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {visibleOptions.map((option) => (
                                            <Dropdown.Item eventKey={option.key} key={option.key} className="text-p">
                                                {option.label}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                    <Row className="main">
                        <Col xxl={5} xl={5} lg={12}>
                            <div className="Desktop-View">
                                <div className="row camera-sec">
                                    <Col xxl={2} xl={2} lg={2} md={2} className="p-0">
                                        <div className="camera-slider">
                                            <Swiper
                                                slidesPerView={"auto"}
                                                modules={[Autoplay]}
                                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 10,
                                                    },
                                                    370: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 10,
                                                    },
                                                    485: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 10,
                                                    },
                                                    575: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 30,
                                                    },

                                                    768: {
                                                        slidesPerView: 6,
                                                        spaceBetween: 20,
                                                    },
                                                    992: {
                                                        slidesPerView: 6,
                                                        spaceBetween: 30,
                                                    },
                                                    1024: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 30,
                                                    },
                                                    1200: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 30,
                                                    },
                                                    1440: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 30,
                                                    },
                                                    2000: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 20,
                                                    },
                                                }}
                                                navigation={true}
                                                pagination={{clickable: true}}
                                                direction={"vertical"}
                                                className="mySwiper"
                                            >
                                                {slidesData.map((slide, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="Main-div">
                                                            <div className="camera-imgs" onClick={slide.onClick}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + slide.image}
                                                                    className="inner-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                            <div className="silder-btn">
                                                <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </div>
                                                <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={10} xl={10} lg={10} md={10} className="">
                                        <div className="card-sec ">
                                            <div className="product-detail text-center">
                                                {selectedImage ? (
                                                    <div>
                                                        <img
                                                            src={selectedImage}
                                                            className="zoomable__img"
                                                            alt="Selected"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="specs">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/assests/ProductDetails/p1.png"
                                                            }
                                                            className="zoomable__img"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </div>

                            {/* this slider for mobile view started*/}
                            <div className="sidebar-swiper-mobile-view">
                                <div className="row camera-sec">
                                    <div className="card-sec ">
                                        <div className="product-detail text-center">
                                            {selectedImage ? (
                                                <div>
                                                    <img src={selectedImage} className="zoomable__img" alt="Selected" />
                                                </div>
                                            ) : (
                                                <div className="specs">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png"}
                                                        className="zoomable__img"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="camera-slider p-3">
                                        <div className="row p-0 m-0">
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <Swiper
                                                    slidesPerView={"auto"}
                                                    modules={[Autoplay]}
                                                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                    breakpoints={{
                                                        0: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 10,
                                                        },
                                                        370: {
                                                            slidesPerView: 3,
                                                            spaceBetween: 10,
                                                        },
                                                        485: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 10,
                                                        },
                                                        575: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 30,
                                                        },

                                                        768: {
                                                            slidesPerView: 6,
                                                            spaceBetween: 20,
                                                        },
                                                        992: {
                                                            slidesPerView: 6,
                                                            spaceBetween: 30,
                                                        },
                                                        1024: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 30,
                                                        },
                                                        1200: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 30,
                                                        },
                                                        1440: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 30,
                                                        },
                                                        2000: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                    navigation
                                                    pagination={{clickable: true}}
                                                    // autoplay={{
                                                    //     delay: 2500,
                                                    //     disableOnInteraction: false,

                                                    //     pauseOnMouseEnter: true,
                                                    //     loop: true,
                                                    // }}
                                                    className="mySwiper"
                                                >
                                                    {slidesData.map((slide, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div className="Main-div">
                                                                <div className="camera-imgs" onClick={slide.onClick}>
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + slide.image}
                                                                        className="inner-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                                <div className="silder-btn">
                                                    <div
                                                        className="back-btn"
                                                        onClick={() => handleMainSliderClick("prev")}
                                                    >
                                                        <FontAwesomeIcon icon={faAngleLeft} />
                                                    </div>
                                                    <div
                                                        className="next-btn"
                                                        onClick={() => handleMainSliderClick("next")}
                                                    >
                                                        <FontAwesomeIcon icon={faAngleRight} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <Swiper
                                            slidesPerView={"auto"}
                                            modules={[Navigation, Autoplay]}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 10,
                                                },
                                                320: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 10,
                                                },
                                                485: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 10,
                                                },
                                                575: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 20,
                                                },

                                                768: {
                                                    slidesPerView: 8,
                                                    spaceBetween: 10,
                                                },
                                                992: {
                                                    slidesPerView: 6,
                                                    spaceBetween: 80,
                                                },
                                                1024: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 10,
                                                },
                                                1200: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 80,
                                                },
                                                1440: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 50,
                                                },
                                                2000: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 20,
                                                },
                                            }}
                                            navigation
                                            pagination={{ clickable: true }}
                                            className="mySwiper"
                                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                                        >
                                            {slidesData?.map((item, index) => (
                                                <SwiperSlide>
                                                    <div className="gallery-imgs">
                                                        <img
                                                            src={item.image}
                                                            className="inner-img"
                                                            alt="Sofa"
                                                        />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <div className="silder-btn">
                                            <div
                                                className="back-btn"
                                                onClick={() => handleMainSliderClick("prev")}
                                            ></div>
                                            <div
                                                className="next-btn"
                                                onClick={() => handleMainSliderClick("next")}
                                            ></div>
                                        </div> */}
                            </div>
                            {/* this slider for mobile view End*/}
                        </Col>
                        <Col xxl={7} xl={7} lg={12}>
                            <Row className="justify-content-center Total">
                                <Col xxl={7} xl={7} lg={7}>
                                    <div className="slider-description">
                                        <h1 className="explore-btn">
                                            CMJ INDUSTRIES Tape Self Adhesive Brown Cello Tape Bopp Industrial Packaging
                                            For E-Commerce Box Packing, Office And Home Use
                                        </h1>
                                        <div className="border-main"></div>
                                        <Row className="mt-3">
                                            <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                                                <p className="sale-price">₹ 1,234.00</p>
                                            </Col>
                                            <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                                                <p className="main-price">
                                                    <span className="main-price price-sale"> ₹ 1,234.00 </span>
                                                    <br></br>(Inclusive of all taxes)
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                                                <p className="text-p rate">Ratings:</p>
                                            </Col>
                                            <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                                                <Rating/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                                                <p className="text-p rate">Sizes:</p>
                                            </Col>
                                            <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                                                <Form>
                                                    <div className="radio-btn-input-holder">
                                                        <Form.Check type="radio" name="name" label="1 Inch" />

                                                        <Form.Check type="radio" name="name" label="2 Inch" />
                                                        <Form.Check type="radio" name="name" label="3 Inch" />
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                                                <p className="text-p rate">Colour:</p>
                                            </Col>
                                            <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                                                <p className="main-price">
                                                    <Form className="color-main">
                                                        <div className="radio-btn-input-holder">
                                                            <Form.Check
                                                                type="radio"
                                                                name="name"
                                                                label="Transparent"
                                                                className="transparent"
                                                            />

                                                            <Form.Check
                                                                type="radio"
                                                                name="name"
                                                                label="Brown"
                                                                className="brown"
                                                            />
                                                        </div>
                                                    </Form>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                                                <p className="text-p rate">Material:</p>
                                            </Col>
                                            <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                                                <p className="text-p">Biaxially Oriented Polypropylene</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xxl={5} xl={5} lg={5} md={5} xs={5} sm={3}>
                                                <p className="text-p rate">Recommended Uses For Product:</p>
                                            </Col>
                                            <Col xxl={7} xl={7} lg={7} md={7} xs={12} sm={9}>
                                                <p className="text-p">Packaging</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xxl={5} xl={5} lg={5} md={6}>
                                    <div className="Payment-Collection">
                                        <p className="price1">₹ 1,234.00</p>
                                        <p className="text-p">
                                            <span className="free">FREE Delivery</span> Sunday, 7 July
                                        </p>
                                        <p className="text-p">
                                            <span className="free">Or fastest Delivery Thursday,</span> <br></br>4 July.
                                        </p>
                                        <div className="sold">
                                            <p className="text-p">Sold By: </p>
                                            <p className="text-p">
                                                <span className="free">CMJ Industries</span>
                                            </p>
                                        </div>
                                        <p className="text-p main">Only 1 Left in stock</p>
                                        <div className="Button-Carts">
                                            <div className="cart">
                                                <Link to={"/cart"}>
                                                    <Button_Common
                                                        className="cart"
                                                        text="Add to Cart"
                                                        icon={faCartPlus}
                                                    />
                                                </Link>
                                            </div>
                                            <div className="cart mt-2">
                                                <Link to={"/checkout"}>
                                                    <Button_Common className="buy" text="Buy Now" icon={faCartPlus} />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="border-main"></div>
                                        <div className="lock">
                                            <FontAwesomeIcon icon={faLock} className="icon-lock" />
                                            <p className="text-p">Secure transaction</p>
                                        </div>
                                        <div className="wishlist">
                                            <Button_Common className="wish" text="Add to Wish List" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={5} xl={5} lg={5} md={5}></Col>
                        <Col xxl={7} xl={7} lg={12} md={12}>
                            <div className="Policy-Main">
                                {!deliveryChecked ? (
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Col sm="3">
                                                <Form.Control type="text" placeholder="Enter Pincode" />
                                            </Col>
                                            <Form.Label
                                                column
                                                sm="9"
                                                className="check-text commmontext"
                                                onClick={handleCheckDelivery}
                                                style={{cursor: "pointer"}}
                                            >
                                                Check Delivery
                                            </Form.Label>
                                        </Form.Group>
                                    </Form>
                                ) : (
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Col sm="3">
                                                <Form.Control type="password" placeholder="41104" />
                                            </Col>
                                            <Form.Label column sm="9" className="check-text">
                                                <ul>
                                                    <li className="service-dot">
                                                        {" "}
                                                        <p className="service small-text">
                                                            Service Available in Osmanabad, Maharashtra
                                                        </p>{" "}
                                                    </li>
                                                </ul>
                                            </Form.Label>
                                        </Form.Group>
                                    </Form>
                                )}
                                <Row>
                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
                                        <div className="policy">
                                            <div className="circle">
                                                <FontAwesomeIcon icon={faHandHoldingHeart} />
                                            </div>
                                            <p className="refund small-text">Non Refundable</p>
                                        </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
                                        <div className="policy">
                                            <div className="circle">
                                                <FontAwesomeIcon icon={faTruck} />
                                            </div>
                                            <p className="refund small-text">Fast Delivery</p>
                                        </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
                                        <div className="policy">
                                            <div className="circle">
                                                <FontAwesomeIcon icon={faBox} />
                                            </div>
                                            <p className="refund small-text">Product saftey</p>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="border-main"></div>
                                <p className="about commmontext">About This item</p>

                                <ul>
                                    <li className="sub-text">
                                        CMJ Tape Made in India is Ideal for Packing E-Commerce Boxes, Packing Cartons,
                                        Export Packaging, and Retail Packing and can be used for Arts & Crafts work or
                                        for as Office Stationery Product, Quality Micron adhesive tape, works best for
                                        arts, stationary, protecting labels from water damage, kid's school projects and
                                        other crafty activities
                                    </li>
                                    <li className="sub-text">
                                        CMJ BOPP tapes being thermoplastic polymer works in both the extreme
                                        temperatures that mean at low as well as high-temperature ranges. The adhesives
                                        used commonly are hot melt synthetic rubber as it seals quickly, reliable and
                                        consistent. These adhesives bonds quickly to the surface with additional
                                        properties like UV, shear and heat resistant. The outstanding features which
                                        complement the tapes are
                                    </li>
                                    <li className="sub-text">
                                        CMJ Polypropylene film can be stretched in both the directions thus mentioned as
                                        biaxial oriented. This stretching of the film increases the strength and
                                        clarity/transparency of the film. High tensile strength and rugged nature making
                                        it ideal to use for packaging and labeling.
                                    </li>
                                    <li className="sub-text">
                                        CMJ Adhesive packing tapes that are used in sealing medium to heavy-duty carton
                                        sealing, shipping, and inventory management and in logistics industries are
                                        actually BOPP tapes.
                                    </li>
                                    <li className="sub-text">
                                        CMJ Polypropylene has several other properties like resistant to abrasion,
                                        chemically reacting agents, burst and moisture. The surface of the film is easy
                                        to print and coat, which makes it useful for custom printed BOPP packing tapes.
                                        The tape can be easily slitting when required
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ProductSlider;
