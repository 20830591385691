import React from "react";
import {Col, Row, Form} from "react-bootstrap";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import PostSubmission from "../CommonComponent/Common_Modal/PostSubmission/PostSubmission";
const OrderDetail = ({handlePrevious, handleNext}) => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <section className="OrderDetail">
            <p className="sub-title mt-4">Single Time Requirement or Repeated Order*</p>

            <Form>
                <div className="order-main">
                    <Form.Check type="radio" name="name" label="Single Time Requirement" className="sub-title" />
                    <Form.Check type="radio" name="name" label="Repeated Order" className="sub-title" />
                </div>
            </Form>
            <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
                <Form.Label className="sub-title">Repeat Order Frequency*</Form.Label>
                <Form.Control type="email" placeholder="After how many days do you need the next order?" />
            </Form.Group>

            <div className="Product">
                <p className="sub-title mt-4">Specific Requirements*</p>
                <Form className="main-product">
                    <div className="radio-btn-input-holder mt-4">
                        <Row>
                            <Col xxl={3}>
                                <Form.Check type="radio" name="name" label="38 Micron" className="sub-title" />
                            </Col>
                            <Col xxl={3}>
                                <Form.Check type="radio" name="name" label="40 Micron" className="sub-title" />
                            </Col>
                            <Col xxl={3}>
                                <Form.Check type="radio" name="name" label="42 Micron" className="sub-title" />
                            </Col>
                            <Col xxl={3}>
                                <Form.Check type="radio" name="name" label="50 Micron" className="sub-title" />
                            </Col>
                            <Col xxl={3}>
                                <Form.Check type="radio" name="name" label="54 Micron" className="sub-title" />
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>

            <div className="apply_now_btn">
                    <Button_Common onClick={handlePrevious} className="back-btn me-3 small-text back" text="BACK">
                        Back
                    </Button_Common>

                    <Button_Common
                    onClick={() => setModalShow(true)}
                        type="button"
                        // onClick={handleNext}
                        className="tabs-btn small-text orange"
                        text="Submit"
                    >
                        Next
                    </Button_Common>
                </div>
                <div className="Customer">
            
            </div>
            <PostSubmission
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
        </section>
     
    );
};

export default OrderDetail;
