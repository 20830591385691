import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./ProductCard.css";
import Heart_Component from "../Heart_Component/Heart_Component";
import Button_Common from "../Button_Common/Button_Common";
import {Rating} from "react-simple-star-rating";
import {faCartPlus,faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import Bottom_Successfull_Modal from "../Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";

const ProductCard = ({image, title, size, saleprice, realprice}) => {
    const [rating, setRating] = useState(0);
    const [cardshow, setCardshow] = useState(false);
    const navigate = useNavigate();

    const handleRating = (rate: number) => {
        setRating(rate);
    };

    const handleClick = () => {
        setCardshow(true);
        setTimeout(() => {
            setCardshow(false);
        }, 1000);
    };
    // Optinal callback functions
    const onPointerEnter = () => console.log("Enter");
    const onPointerLeave = () => console.log("Leave");
    const onPointerMove = (value: number, index: number) => console.log(value, index);
    return (
        <>
            <section className="ProductCard">
                {/* <Row>
                    <Col xxl={2}> */}
                <Card>
                    <div className="Product-card">
                        <div className="Main">
                            <Link to={"/sample-roll"}>
                                <div className="Image-Tape">
                                    <img src={image} className="tape" />
                                </div>
                            </Link>
                            <div className="heart">
                                <Heart_Component />
                                <Link to={"/comparelist"}>
                                    <div className="compare mt-1">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Products/compress.png"}
                                            className="tape"
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="triangle-element"></div> */}
                        {/* <div className="sale-off">
                            <p className="off">
                                20% <br></br>off
                            </p>
                        </div> */}
                    </div>
                    <Card.Body>
                        <p className="sub-text">{title}</p>
                        <p className="sub-text">{size}</p>
                        <div className="price">
                            <p className="sale-price">{saleprice}</p>
                            <p className="main-price">
                                <span className="real-price">{realprice} </span>
                                <br></br>(Inclusive of all taxes)
                            </p>
                        </div>
                        <div className="rating">
                            <p className="sub-text">Ratings:</p>
                            <Rating
                                onClick={handleRating}
                                onPointerEnter={onPointerEnter}
                                onPointerLeave={onPointerLeave}
                                onPointerMove={onPointerMove}
                            />
                        </div>
                        <div className="Cart-Btn mt-2">
                            <div className="cart">
                                <Button_Common
                                    icon={faCartPlus}
                                    text="Add to Cart"
                                    className="cart sub-text"
                                    onClick={handleClick}
                                />
                            </div>
                            <div className="buy">
                                <Button_Common
                                    icon={faCartPlus}
                                    text="Buy Now"
                                    className="buy sub-text"
                                    onClick={() => navigate("/checkout")}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                {/* </Col>
                </Row> */}

                <Bottom_Successfull_Modal
                    onClick={() => setCardshow(true)}
                    show={cardshow}
                   icon={faCircleCheck}
                    text="Product added to the cart successfully!"
                />
            </section>
        </>
    );
};

export default ProductCard;
