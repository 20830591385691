import React from "react";
import "./Customer_Support_Details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

function Customer_Support_Details() {
  return (
    <>
      <div className="Customer_Support_Details">
        <div className="container">
          <div className="Customer_Support_Details_holder ">
            <div className="row">
              <div className="col-xxl-8  col-xl-8 col-lg-8 col-md-12 order-lg-1 order-2">
                <div className="details-holder">
                  <p className="privecy_subtitle">
                    Customer Support Details & Contact Info
                  </p>
                  <p className="privcytext">
                    If you have any questions or comments about these our Terms
                    of Service as outlined above, you can contact us at:
                  </p>

                  <div className="address-info-section">
                    <div className="row mb-md-0 mb-3">
                      <div className="col-md-4">
                        <p className="info-text">
                          <FontAwesomeIcon
                            className="icon-font"
                            icon={faLocationDot}
                          />{" "}
                          Address:-
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="info-text">
                          CMJ INDUSTRIES PUNE, Gat No 1582, Bhangre Industrial
                          Complex, Shop No 13 Near Balaji Tower,
                          Chikhali-Talwade Road, 411062
                        </p>
                      </div>
                    </div>

                    <div className="row mb-md-0 mb-3">
                      <div className="col-md-4">
                        <p className="info-text">
                          <FontAwesomeIcon
                            className="icon-font"
                            icon={faEnvelope}
                          />{" "}
                          Email Id:-
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="info-text">smitalad2000@gmail.com</p>
                      </div>
                    </div>

                    <div className="row mb-md-0 mb-3">
                      <div className="col-md-4">
                        <p className="info-text">
                          <FontAwesomeIcon
                            className="icon-font"
                            icon={faPhone}
                          />{" "}
                          Contact No: -
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="info-text">8329717621</p>
                      </div>
                    </div>

                    <div className="row mb-md-0 mb-3">
                      <div className="col-md-4">
                        <p className="info-text">
                          <FontAwesomeIcon
                            className="icon-font"
                            icon={faCircleQuestion}
                          />{" "}
                          Customer Support: -
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="info-text">7558759857</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-12 order-lg-2 order-1">
                <div className="image-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assests/terms-and-condition/terms-and-condition-banner.png"
                    }
                    className="terms-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customer_Support_Details;
