import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./AdhesiveQuality.css";
const AdhesiveQuality = () => {
    return (
        <>
            <section className="Adhesive">
                <Container>
                    <div className="Adhesive-Quality">
                        <Row className="justify-content-center">
                            <Col xxl={10} xl={10} lg={10}>
                                <p className="explore-btn">Superb Adhesive Quality</p>
                                <div className="industry-main">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assests/ProductDetails/3.png"}
                                        className="industry"
                                    />
                                </div>
                                <div className="banner-two">
                                    <p className="explore-btn">Best for Packaging</p>
                                    <div className="industry-main">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/ProductDetails/4.png"}
                                            className="industry"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default AdhesiveQuality;
