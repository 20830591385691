import React from "react";
import "./Personalise.css";
import { Container } from "react-bootstrap";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
import { Link } from "react-router-dom";
function Personalise() {
  return (
    <>
      <section className="personal_section">
        <Container>
          <div className="personal_div">
            <div className="personalbanner">
              <img
                className="personalimage"
                src={
                  process.env.PUBLIC_URL +
                  "/assests/HomePage/Personalise/personalbanner.png"
                }
              />
            </div>
            <div className="btndiv">
              <Link to={"/customize-tape"}>
                <Common_Button
                  className="button button--webcoderskull button--border-thick button--text-medium explore-btn"
                  text="Requirement Form"
                />
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Personalise;
