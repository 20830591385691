import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./ProductCategory.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../../CommonComponent/ProductCard/ProductCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autoplay } from "swiper/modules";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
const ProductCategory = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstance2, setSwiperInstance2] = useState(null);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const handleMainSliderClick2 = (direction) => {
        if (swiperInstance2) {
            if (direction === "prev") {
                swiperInstance2.slidePrev();
            } else if (direction === "next") {
                swiperInstance2.slideNext();
            }
        }
    };
    const product = [
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
    ];
    const product2 = [
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
    ];
    return (
        <>
            <section className="ProductCategory">
                <Container>
                    <h1 className="title-main">
                        <span className="main-title-border">PRODUCTS</span> CATEGORY
                    </h1>
                    <div className="Product-Main">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="pills" className="justify-content-center">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Transparent Tape</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Brown Tape</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Swiper
                                        className="mySwiper"
                                        // pagination={true}
                                        modules={[Autoplay]}

                                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            380: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            485: {
                                                slidesPerView: 1.3,
                                                spaceBetween: 10,
                                            },
                                            575: {
                                                slidesPerView: 1.4,
                                                spaceBetween: 10,
                                            },

                                            768: {
                                                slidesPerView: 2.3,
                                                spaceBetween: 10,
                                            },
                                            992: {
                                                slidesPerView: 3,
                                                spaceBetween: 10,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 10,
                                            },
                                            1200: {
                                                slidesPerView: 3.3,
                                                spaceBetween: 10,
                                            },
                                            1440: {
                                                slidesPerView: 4.3,
                                                spaceBetween: 10,
                                            },
                                            2000: {
                                                slidesPerView: 4.3,
                                                spaceBetween: 10,
                                            },
                                        }}
                                    >
                                        {product.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <ProductCard
                                                    image={item.image}
                                                    title={item.title}
                                                    size={item.size}
                                                    saleprice={item.saleprice}
                                                    realprice={item.realprice}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="silder-btn">
                                        <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                            <FontAwesomeIcon icon={ faAngleLeft} className="icon-main" />
                                        </div>
                                        <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                            <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Swiper
                                        className="mySwiper"
                                        // pagination={true}
                                        modules={[Autoplay]}

                                        onSwiper={(swiper) => setSwiperInstance2(swiper)}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            380: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            485: {
                                                slidesPerView: 2,
                                                spaceBetween: 10,
                                            },
                                            575: {
                                                slidesPerView: 3,
                                                spaceBetween: 10,
                                            },

                                            768: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                            },
                                            992: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                            },
                                            1024: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                            },
                                            1200: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                            },
                                            1440: {
                                                slidesPerView: 4.3,
                                                spaceBetween: 10,
                                            },
                                            2000: {
                                                slidesPerView: 4.3,
                                                spaceBetween: 10,
                                            },
                                        }}
                                    >
                                        {product2.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <ProductCard
                                                    image={item.image}
                                                    title={item.title}
                                                    size={item.size}
                                                    saleprice={item.saleprice}
                                                    realprice={item.realprice}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="silder-btn">
                                        <div className="back-btn" onClick={() => handleMainSliderClick2("prev")}>
                                            <FontAwesomeIcon icon={ faAngleLeft} className="icon-main" />
                                        </div>
                                        <div className="next-btn" onClick={() => handleMainSliderClick2("next")}>
                                            <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default ProductCategory;
