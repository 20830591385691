import React, { useEffect } from "react";
import HomeBanner from "./HomeBanner/HomeBanner";
import ChooseUs from "./ChooseUs/ChooseUs";
import ProductCategory from "./ProductCategory/ProductCategory";

import Tape_Requirement from "./Tape_Requirement/Tape_Requirement";
import We_Offer from "./We_Offer/We_Offer";
import Deals from "./Deals/Deals";
import Personalise from "./Personalise/Personalise";
import SaleIsLive from "./SaleIsLive/SaleIsLive";
import Inquiry from "./Inquiry/Inquiry";
import PeopleSays from "./PeopleSays/PeopleSays";

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    return (
        <>
            <HomeBanner />
            <ChooseUs />
            <ProductCategory />
            <Deals />
            <We_Offer />
            {/* <Tape_Requirement /> */}
            <Personalise />
            <SaleIsLive />
            <Inquiry />
            <PeopleSays />
        </>
    );
};

export default HomePage;
