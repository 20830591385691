import React, { useEffect, useState } from "react";
import { Container, Row, Table } from "react-bootstrap";
import Breadcrumbs from "../../CommonComponent/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const View_Order_Details = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "My account", link: "" },
    { text: "Order", link: "/yourOrders", className: "" },
    { text: "View Order Details", link: "", className: "active" },
  ]);

  const Counter = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const [count, setCount] = useState(0);

    const increaseCount = () => {
      setCount((prevCount) => prevCount + 1);
    };

    const decreaseCount = () => {
      setCount((prevCount) => Math.max(prevCount - 1, 0));
    };

    return (
      <span className="conutssdiv me-3">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="ficnn px-1"
          onClick={decreaseCount}
        />
        <span className="px-1">{count}</span>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="ficnn px-1"
          onClick={increaseCount}
        />
      </span>
    );
  };

  const tabheading = [
    { name: "Sr. No." },
    { name: "Name" },
    { name: "MRP" },
    { name: "Price" },
    { name: "Quantity" }
  ];

  const tabledata2 = [
    {
      sr_no: "1",
      product_name: "Brown Tape",
      mrp: "500 ",
      price: "452",
      quantity: "5"
    },
   

    {
      sr_no: "2",
      product_name: "black Tape",
      mrp: "254 ",
      price: "533",
      quantity: "3"
    },
   

    {
      sr_no: "3",
      product_name: "white Tape",
      mrp: "532 ",
      price: "553",
      quantity: "54"
    },
   

    {
      sr_no: "4",
      product_name: "red Tape",
      mrp: "8622 ",
      price: "5253",
      quantity: "7"
    },
   

    {
      sr_no: "4",
      product_name: "yellow Tape",
      mrp: "25 ",
      price: "55",
      quantity: "2"
    },
   

    {
      sr_no: "6",
      product_name: "green Tape",
      mrp: "5870",
      price: "45522",
      quantity: "55"
    },
   
   
  ];

  return (
    <>
      <section className="Order cattable_section">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="cattable_div">
            <div className="cattable_main">
              <Table responsive className="tablees text-center">
                <thead>
                  <tr>
                    {tabheading.map((heading, index) => (
                      <th
                        className={`headdingss ${
                          index === 0 ? "first-heading" : ""
                        } ${
                          index === tabheading.length - 1 ? "last-heading" : ""
                        }`}
                        key={index}
                      >
                        {heading.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tabledata2.map((data, index) => (
                    <tr key={index}>
                      <td>
                       {data.sr_no}
                      </td>
                      <td className="bottomtd commmontext">
                        {data.product_name}
                      </td>
                      <td className="bottomtd commmontext">{data.mrp}</td>
                      <td className="bottomtd commmontext">{data.price}</td>
                      <td className="bottomtd commmontext">{data.quantity}</td>
                    
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default View_Order_Details;
