import React from "react";
import "./We_Offer.css";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
import { Link } from "react-router-dom";

function We_Offer() {
    return (
        <>
            <section className="We_Offer">
                <div className="container">
                    <div className="We_Offer_bg_img_text_holder">
                        <div className="We_Offer_bg_img_holder">
                            <img
                                src={process.env.PUBLIC_URL + "/assests/HomePage/WeOffer/offer.png"}
                                className="We_Offer_bg_img"
                            />
                        </div>

                        <div className="btn-holder">
                        <Link to={"/cmj-industries"}>
                            <Common_Button
                                className="button button--webcoderskull button--border-thick button--text-medium explore-btn"
                                text="Know More About Us"
                            />
                        </Link>
                        </div>
                    </div>
                </div>
            </section>
          
        </>
    );
}

export default We_Offer;
