import React, { useEffect } from "react";
import {Col, Form, Row} from "react-bootstrap";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
const PaymentOption = ({handlePrevious, handleNext}) => {
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    return (
        <>
            <section className="checkout-inner-steps">
                <div className="">
                    <div className="adresmain">{/* <h3>Thank You for Trusting Us</h3> */}</div>
                    <Row>
                        <Col xxl={8} xl={8} lg={8}>
                            <div className="Choose_Payment_Option">
                                <div className="heading-holder">
                                    <h4 className="price-text">Choose Payment Option</h4>
                                </div>

                                <div>
                                    <Form>
                                        <div className="radio-btn-input-holder">
                                            <Form.Check 
                                                type="radio"
                                                name="name"
                                                label="Cash on delivery"
                                            />

                                            <Form.Check
                                                type="radio"
                                                name="name"
                                                label="Online"
                                            />
                                        </div>
                                    </Form>
                                </div>
                                <Form>
                                    <div className="payment_method_card bodr_bottom">
                                        <div className="heading-holder">
                                            <h4>Pay with credit card</h4>
                                        </div>

                                        <div className="paycredit">
                                            <div className="flex-radio-input">
                                                <Form.Check // prettier-ignore
                                                    type="radio"
                                                    name="online"
                                                />

                                                <div className="payment-logo-holder">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assests/Address/visa.png"}
                                                        className="payment-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-radio-input">
                                                <Form.Check // prettier-ignore
                                                    type="radio"
                                                    name="online"
                                                />

                                                <div className="payment-logo-holder">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assests/Address/mastercard.png"}
                                                        className="payment-logo"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="payment_method_card">
                                        <div className="heading-holder">
                                            <h4>Pay with UPI</h4>
                                        </div>

                                        <div className="paycredit">
                                            <div className="flex-radio-input">
                                                <Form.Check // prettier-ignore
                                                    type="radio"
                                                    name="online"
                                                />

                                                <div className="payment-logo-holder">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assests/Address/gpay.png"}
                                                        className="payment-logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex-radio-input">
                                                <Form.Check // prettier-ignore
                                                    type="radio"
                                                    name="online"
                                                />

                                                <div className="payment-logo-holder">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assests/Address/paytm.png"}
                                                        className="payment-logo"
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex-radio-input">
                                                <Form.Check // prettier-ignore
                                                    type="radio"
                                                    name="online"
                                                />

                                                <div className="payment-logo-holder">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assests/Address/paytm.png"}
                                                        className="payment-logo"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4}>
                            <div className="Primary-Contact">
                                <p className="sub-title">Primary Contact</p>
                                <p className="sub-text ">Rimsha</p>
                                <p className="sub-text">
                                    Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016
                                </p>
                            </div>
                            <div className="  apply_now_btn">
                                <Button_Common
                                    onClick={handlePrevious}
                                    className="back-btn me-3 small-text back"
                                    text="BACK"
                                >
                                    Back
                                </Button_Common>

                                <Button_Common
                                    type="button"
                                    onClick={handleNext}
                                    className="tabs-btn small-text orange"
                                    text="SAVE & NEXT"
                                >
                                    Next
                                </Button_Common>
                            </div>
                        </Col>
                    </Row>
                </div>

              
            </section>
        </>
    );
};

export default PaymentOption;
