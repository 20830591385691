import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import "./Common.css";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import {Link} from "react-router-dom";
import EditAddress from "../CommonComponent/Common_Modal/EditAddress/EditAddress";
// import Edit_Address_Modal from "../CommonComponents/Common_Modal/Edit_Address_Modal/Edit_Address_Modal";
const AddressDetail = ({handleNext}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const options = [
        {value: "chocolate", label: "Chocolate"},
        {value: "strawberry", label: "Strawberry"},
        {value: "vanilla", label: "Vanilla"},
    ];

    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <section className="Address">
                <Row>
                    <Col xxl={8} xl={8} lg={12}>
                        <div className="Address-Content">
                            <div className="main-heading-text">
                                <div className="mb-3">
                                    <Form.Check
                                        type="radio"
                                        name="name"
                                        label="Add new address"
                                        className="small-text"
                                    />
                                </div>
                            </div>
                            <Form className="address-form">
                                <Form.Group as={Row} className="mb-3" controlId="formAddressName">
                                    <Form.Label
                                        column
                                        xxl="3"
                                        xl="3"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                        xs="12"
                                        className="small-text"
                                    >
                                        Name of address*
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Select
                                            classNamePrefix="selectOptions"
                                            options={options}
                                            placeholder="--- please select ---"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formFlatNo">
                                    <Form.Label
                                        column
                                        xxl="3"
                                        xl="3"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                        xs="12"
                                        className="small-text"
                                    >
                                        Flat no./House/bldg*
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Form.Control type="text" placeholder="Flat no./House/bldg" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formColony">
                                    <Form.Label
                                        column
                                        xxl="3"
                                        xl="3"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                        xs="12"
                                        className="small-text"
                                    >
                                        Colony/street/landmark
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Form.Control type="text" placeholder="Colony/street/landmark" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formZipCode">
                                    <Form.Label
                                        column
                                        xxl="3"
                                        xl="3"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                        xs="12"
                                        className="small-text"
                                    >
                                        Zip code*
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Form.Control type="text" placeholder="Zip code" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formMobileNumber">
                                    <Form.Label
                                        column
                                        xxl="3"
                                        xl="3"
                                        lg="3"
                                        md="3"
                                        sm="12"
                                        xs="12"
                                        className="small-text"
                                    >
                                        Mobile number*
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Form.Control type="text" placeholder="Mobile number" />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={12} md={12} className="right_border">
                        <div className="main-address">
                            <div className="Main-address">
                                <div className="Edit">
                                    <div className="image-map">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Checkout/map.png"}
                                            className="map"
                                            alt="Mansa Nutri Logo"
                                        />
                                        <div className="circle">
                                            <FontAwesomeIcon icon={faLocationDot} />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p className="small-text">Home</p>
                                        <p className="sub">John Doe</p>
                                        <p className="sub">
                                            E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703
                                        </p>
                                    </div>
                                    <div className="button-main">
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault1"
                                            />
                                        </div>
                                        <p className="text sub" onClick={() => setModalShow(true)}>
                                            Edit
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="Main-address">
                                <div className="Edit">
                                    <div className="image-map">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Checkout/map.png"}
                                            className="map"
                                            alt="Mansa Nutri Logo"
                                        />
                                        <div className="circle">
                                            <FontAwesomeIcon icon={faLocationDot} />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <p className="small-text">Home</p>
                                        <p className="sub">John Doe</p>
                                        <p className="sub">
                                            E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703
                                        </p>
                                    </div>
                                    <div className="button-main">
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault1"
                                            />
                                        </div>
                                        <p className="text sub" onClick={() => setModalShow(true)}>
                                            Edit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="  apply_now_btn">
                            <Link to={""}>
                                <Button_Common text="Back" className=" back-btn small-text back me-3" />
                            </Link>

                            <Button_Common
                                type="button"
                                onClick={handleNext}
                                className="tabs-btn small-text orange"
                                text="Save & Next"
                            >
                                Next
                            </Button_Common>
                        </div>
                    </Col>
                </Row>
                <EditAddress show={modalShow} onHide={() => setModalShow(false)} />
            </section>
        </>
    );
};

export default AddressDetail;
