import React from 'react'
import CartComponent from './CartComponent/CartComponent'
import TopPicks from './TopPicks/TopPicks'

const CartMain = () => {
  return (
    <>
      <CartComponent/>
      <TopPicks/>
    </>
  )
}

export default CartMain
