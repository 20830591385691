import React, {useState} from "react";
import "./CmjBanner.css";
import {Col, Container, Row} from "react-bootstrap";
import Breadcrumbs from "../../CommonComponent/Breadcrumbs/Breadcrumbs";
const CmjBanner = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "Why CMJ Industries?", link: "/cmj-industries", className: "active"},
    ]);
    return (
        <>
            <section className="CmjBanner">
                <Container>
                    <Breadcrumbs items={breadcrumbItems} />

                    <div className="Cmj-Main-banner">
                        <img className="cmjbanner" src={process.env.PUBLIC_URL + "/assests/Cmj/cmjbanner.png"} />
                    </div>
                    <Row className="justify-content-center content">
                        <Col xxl={10}>
                            <div className="background">
                                <h1 className="title-main">
                                    <span className="main-title-border">Why CMJ</span> Industries?
                                </h1>
                                <p className="sub-title mt-4">
                                    "Welcome to CMJ INDUSTRIES, a leading manufacturer of Transparent Tape, Brown Tape,
                                    Bopp Tape Self-adhesive Tapes and Packaging Tapes. Established in 2019 in Pune,
                                    Maharashtra, we have quickly become a trusted name in the industry. As a sole
                                    proprietorship firm, we take pride in our ability to provide personalized attention
                                    to each of our clients, ensuring that their needs are met with the highest level of
                                    quality and service. Our product range includes Transparent Tape, Brown Tape,
                                    Self-Adhesive Tapes, BOPP Tape, and other packaging materials that cater to a wide
                                    range of industries. We are committed to providing premium quality products at
                                    affordable prices, making us a preferred choice among our clients. Our team of
                                    experienced professionals works tirelessly to ensure that every product meets the
                                    highest standards of quality and performance
                                </p>
                                <p className="sub-title">
                                    At CMJ Industries, we understand the importance of timely delivery and strive to
                                    ensure that our products reach our clients on time, every time. This has enabled us
                                    to build a loyal client base across the country, and we continue to work towards
                                    expanding our reach and serving more clients in the years to come.
                                </p>
                                <p className="sub-title">
                                    Our mission is to provide innovative packaging solutions that meet the evolving
                                    needs of our clients, while maintaining the highest level of quality, service, and
                                    integrity. We believe in building long-term relationships with our clients and are
                                    committed to delivering exceptional value to them."
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default CmjBanner;
