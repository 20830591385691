import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";

import React, { useState } from "react";
import { Card } from "react-bootstrap";

import "./Heart_Component.css";

function Heart_Component() {
    const [isFavorite, setIsFavorite] = useState(false);

    const toggleFavorite = () => {
        setIsFavorite(!isFavorite);
    };

    return (
        <div className="heart-component">
           
                    <div onClick={toggleFavorite} className="favorite-button">
                        <FontAwesomeIcon
                            icon={isFavorite ? solidHeart : regularHeart}
                            className={isFavorite ? "red-heart" : "black-heart"}
                        />
                    </div>
              
        </div>
    );
}

export default Heart_Component;
