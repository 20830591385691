import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
const ProductInformation = ({handlePrevious, handleNext}) => {
    return (
        <>
            <section className="ProductInformation">
                <div className="Product">
                    <p className="sub-title">Product Name*</p>
                    <Form className="main-product">
                        <div className="radio-btn-input-holder">
                            <Row>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check
                                        type="radio"
                                        name="name"
                                        label="Transparent Tape"
                                        className="sub-title"
                                    />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="Brown Tape" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="Black Tape" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="Green Tape" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="Brown Tape" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="Black Tape" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="Green Tape" className="sub-title" />
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
                <div className="Product">
                    <p className="sub-title">Size*</p>
                    <Form className="main-product">
                        <div className="radio-btn-input-holder">
                            <Row>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="1”Inch" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="2”Inch" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="3”Inch" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="4”Inch" className="sub-title" />
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
                <div className="Product">
                    <p className="sub-title">Meter per Roll*</p>
                    <Form className="main-product">
                        <div className="radio-btn-input-holder">
                            <Row>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="30 Meter" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="40 Meter" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="50 Meter" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="65 Meter" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="100 Meter" className="sub-title" />
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
                <div className="Product">
                    <p className="sub-title">Box Quantity*</p>
                    <Form className="main-product">
                        <div className="radio-btn-input-holder">
                            <Row>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="5 Boxes" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="10 Boxes" className="sub-title" />
                                </Col>
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6}>
                                    <Form.Check type="radio" name="name" label="Above" className="sub-title" />
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
                <div className="apply_now_btn">
                    <Button_Common onClick={handlePrevious} className="back-btn me-3 small-text back" text="BACK">
                        Back
                    </Button_Common>

                    <Button_Common
                        type="button"
                        onClick={handleNext}
                        className="tabs-btn small-text orange"
                        text="SAVE & NEXT"
                    >
                        Next
                    </Button_Common>
                </div>
            </section>
        </>
    );
};

export default ProductInformation;
