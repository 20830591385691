import React from "react";
import {Container} from "react-bootstrap";
import "./TopPicks.css";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {Pagination} from "swiper/modules";
import ProductCard from "../../CommonComponent/ProductCard/ProductCard";

const TopPicks = () => {
    const product = [
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
    ];
    return (
        <>
            <section className="TopPicks">
                <Container>
                    <h1 className="title-main">
                        <span className="main-title-border">Top Pic</span>ks
                    </h1>
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            486: {
                                slidesPerView: 1.5,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                            1400: {
                                slidesPerView: 4,
                                spaceBetween: 15,
                            },
                            1900: {
                                slidesPerView: 4,
                                spaceBetween: 15,
                            },
                        }}
                        pagination={{
                            dynamicBullets: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {product.map((item, index) => (
                            <SwiperSlide key={index}>
                                <ProductCard
                                    image={item.image}
                                    title={item.title}
                                    size={item.size}
                                    saleprice={item.saleprice}
                                    realprice={item.realprice}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            </section>
        </>
    );
};

export default TopPicks;
