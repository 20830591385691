import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
const CustomerInformation = ({handleNext}) => {
    return (
        <>
            <section className="Customer-Information">
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="sub-title">Customer Full Name*</Form.Label>
                        <Form.Control type="name" placeholder="" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="sub-title">Address*</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                </Form>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="sub-title">Phone Number*</Form.Label>
                            <Form.Control type="email" placeholder="" />
                        </Form.Group>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className="sub-title">Email Address*</Form.Label>
                            <Form.Control type="email" placeholder="" />
                        </Form.Group>
                    </Col>
                </Row>
            <div className="Save-Btn">
            <Button_Common
                    type="button"
                    onClick={handleNext}
                    className="tabs-btn small-text orange"
                    text="Save & Next"
                >
                    Next
                </Button_Common>
            </div>
               
            </section>
        </>
    );
};

export default CustomerInformation;
