import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Bottom_Successfull_Modal.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function Bottom_Successfull_Modal({ show, onHide, icon, text }) {
    // useEffect(() => {
    //     let timer;

    //     if (show) {
    //         timer = setTimeout(() => {
    //             onHide();

    //         }, 1000);
    //     }

    //     return () => clearTimeout(timer);
    // }, [show, onHide]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            className="Bottom_Successfull_Modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Footer>
                <FontAwesomeIcon icon={icon} className="success-icon" />
                <span className="success-text">{text}</span>
                <Button
                    variant="secondary"
                    className="close-btn"
                    onClick={onHide}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Bottom_Successfull_Modal;
