import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import Bottom_Successfull_Modal from "../../CommonComponent/Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
function Set_New_Password(props) {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisibleone, setPasswordVisibleone] = useState(false);
    const navigate = useNavigate(); // Initialize the useNavigate hook
    const [modalBottomSuccessfullShow, setModalBottomSuccessModalFalse] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const togglePasswordVisibilityone = () => {
        setPasswordVisibleone(!passwordVisibleone);
    };

    const handleResetPasswordSubmit = () => {
        setModalBottomSuccessModalFalse(true);
        setTimeout(() => {
            setModalBottomSuccessModalFalse(false);
            navigate("/login");
        }, 3000);
    };

    return (
        <>
            <div className="Modal-holder">
                <div className="LogIn">
                    <div className="row bg-color me-0 ms-0 justify-content-center LogIn-holder">
                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-8">
                            <div className="log_in_form">
                                <div className="Main-starting">
                                    <div className="login-box-button">
                                        <p>Set New Password</p>
                                    </div>

                                    <div className="text-holder">
                                        <p className="note-text sub-title">
                                            Your new password must be Different from the previously used password.
                                        </p>
                                    </div>

                                    <Form>
                                        <Form.Group className=" mb-3" controlId="">
                                            <Form.Label className="small-text ">Enter your password</Form.Label>
                                            <div className="password-field-holder">
                                                <Form.Control
                                                    type={passwordVisible ? "text" : "password"}
                                                    placeholder="Password"
                                                />
                                                <div className="eye-icon-holder" onClick={togglePasswordVisibilityone}>
                                                    <FontAwesomeIcon
                                                        className="eye-icon"
                                                        icon={passwordVisibleone ? faEye : faEyeSlash}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className=" mb-3" controlId="">
                                            <Form.Label className="small-text ">confirm password</Form.Label>
                                            <div className="password-field-holder">
                                                <Form.Control
                                                    type={passwordVisible ? "text" : "password"}
                                                    placeholder="Password"
                                                />
                                                <div className="eye-icon-holder" onClick={togglePasswordVisibility}>
                                                    <FontAwesomeIcon
                                                        className="eye-icon"
                                                        icon={passwordVisible ? faEye : faEyeSlash}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>

                                        <div className="btn-holder">
                                            <Common_Button
                                                className="button button--webcoderskull button--border-thick button--text-medium login-btn  reset sub-title orange"
                                                text="Reset Password"
                                                onClick={handleResetPasswordSubmit}
                                            />
                                        </div>
                                    </Form>

                                    <Link to="/">
                                        <div className="back_to_span">
                                            <p>Back to Home {">"}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom_Successfull_Modal
                show={modalBottomSuccessfullShow}
                onHide={() => {
                    setModalBottomSuccessModalFalse(false);
                }}
                icon={faCircleCheck}
                text={"Password Reset Successfully!"}
            />
        </>
    );
}

export default Set_New_Password;
