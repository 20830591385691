import React, { useEffect } from "react";
import "./EditAddress.css";
import Button_Common from "../../Button_Common/Button_Common";
import Modal from "react-bootstrap/Modal";
import { Col, Form, Row } from "react-bootstrap";
import "./EditAddress.css";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck,  } from "@fortawesome/free-solid-svg-icons";
const EditAddress = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    let timer;
    if (modalShow) {
      timer = setTimeout(() => {
        setModalShow(false);
      }, 2000);
    }

    return () => clearTimeout(timer); // Cleanup the timer
  }, [modalShow]); // Dependency array
  return (
    <>
      <section className="EditAddress">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="EditAddress"
        >
          {/* <Modal.Body> */}
          <div className="Modal-holder">
            <div className="log_in_form">
              <div className="login-box-button">
                <p>Edit Address</p>
              </div>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">Name*</Form.Label>
                  <Form.Control type="name" placeholder="name" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">
                    Name of address*
                  </Form.Label>
                  <Form.Control type="name" placeholder="name" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">
                    Flat no./House/bldg*
                  </Form.Label>
                  <Form.Control type="name" placeholder="name" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="sub-title">Zipcode*</Form.Label>
                  <Form.Control type="name" placeholder="name" />
                </Form.Group>
              </Form>
              <div className="Button-Main">
                <Button_Common text="Cancel" className="cancel" />
                <Button_Common
                  text="Submit"
                  className="submit orange"
                  onClick={() => {
                    setModalShow(true);
                    props.onHide();
                  }}
                />
              </div>
            </div>
          </div>
          {/* </Modal.Body> */}
        </Modal>

        <Bottom_Successfull_Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          icon={faCircleCheck}
          text={"Address Changed Successfully!"}
        />
      </section>
    </>
  );
};

export default EditAddress;
