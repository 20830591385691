import React, { useEffect, useState } from 'react';
import './Categorytable.css';
import { Container, Form, Table } from 'react-bootstrap';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from "react-bootstrap/Dropdown";

const Counter = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    const [count, setCount] = useState(0);

    const increaseCount = () => {
        setCount(prevCount => prevCount + 1);
    };

    const decreaseCount = () => {
        setCount(prevCount => Math.max(prevCount - 1, 0));
    };

    return (
        <span className='conutssdiv me-3'>
            <FontAwesomeIcon icon={faChevronLeft} className='ficnn px-1' onClick={decreaseCount} />
            <span className='px-1'>{count}</span>
            <FontAwesomeIcon icon={faChevronRight} className='ficnn px-1' onClick={increaseCount} />
        </span>
    );
};

function Categorytable() {
    const [selectedCategory, setSelectedCategory] = useState("Brown Tape");

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const [selectedOption, setSelectedOption] = useState("Transparent Tape");

    const handleSelect = (eventKey) => {
        setSelectedOption(eventKey);
        setIsOpen(!isOpen);
    };

    const options = [
        {key: "Transparent Tape", label: "Transparent Tape"},
        {key: "Brown Tape", label: "Brown Tape"},
    ];

    const [isOpen, setIsOpen] = useState(false);
  

    const visibleOptions = options.filter((option) => option.key !== selectedOption);

    const tabheading = [
        { name: "SKU" },
        { name: "Width" },
        { name: "Length" },
        { name: "Thickness" },
        { name: "Colour" },
        { name: "Per Box Units" },
        { name: "Weight" },
        { name: "Availability" },
        { name: "Price per unit" },
        { name: "Add to Cart" },
    ];

    const tabledata = [
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "In stock",
            price: "$50.95",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "out of stock",
            price: "$50.95",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "In stock",
            price: "$50.95",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "out of stock",
            price: "$50.95",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "In stock",
            price: "$50.95",
        },
    ];
    const tabledata2 = [
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "out of stock",
            price: "$50.95",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "In stock",
            price: "$50.95",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "out of stock",
            price: "$50.95",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "In stock",
            price: "$50.95",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
            name: "BRN-Single-1-40-144",
            width: "1/2 ",
            length: "60 yds",
            thickness: "5.6 mil",
            colours: "Crepe",
            units: "72",
            weights: "13 Ibs",
            stock: "out of stock",
            price: "$50.95",
        },
    ];

    return (
        <section className='cattable_section'>
            <Container>
                <div className='cattable_div'>
                    <div className='dropdiv mb-3'>
                        {/* <p className='text-p mb-0 pe-4'>Category</p>
                        <Form.Select className='text-p formmselect' aria-label="Default select example" value={selectedCategory} onChange={handleCategoryChange}>
                            <option className='text-p' value="Brown Tape">Brown Tape</option>
                            <option className='text-p' value="Transparent Tape">Transparent Tape</option>
                        </Form.Select>
                        */}
                                <p className="text-p mb-0">Category</p>
                                <Dropdown onSelect={handleSelect}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="text-p">
                                        {selectedOption}
                                        <span className={`arrow-icon ${isOpen ? 'open' : ''}`}></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {visibleOptions.map((option) => (
                                            <Dropdown.Item eventKey={option.key} key={option.key} className="text-p">
                                                {option.label}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            
                    </div>
                    {selectedCategory === "Brown Tape" && (
                        <div className='cattable_main'>
                            <Table responsive className='tablees text-center'>
                                <thead>
                                    <tr>
                                        {tabheading.map((heading, index) => (
                                            <th
                                                className={`headdingss ${index === 0 ? 'first-heading' : ''} ${index === tabheading.length - 1 ? 'last-heading' : ''}`}
                                                key={index}
                                            >
                                                {heading.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tabledata.map((data, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className='imggdiv'>
                                                    <img src={data.image} className='tapeimgg' alt="Product" />
                                                    <p className='smalltexxx mb-0'>{data.name}</p>
                                                </div>
                                            </td>
                                            <td className='bottomtd commmontext'>{data.width}"<span className='smalltexxx'> inches</span></td>
                                            <td className='bottomtd commmontext'>{data.length}</td>
                                            <td className='bottomtd commmontext'>{data.thickness}</td>
                                            <td className='bottomtd commmontext'>{data.colours}</td>
                                            <td className='bottomtd commmontext'>{data.units}</td>
                                            <td className='bottomtd commmontext'>{data.weights}</td>
                                            <td className={`bottomtd commontext ${data.stock === 'In stock' ? 'greencol' : data.stock === 'out of stock' ? 'redcol' : ''}`}>
                                                {data.stock}
                                            </td>
                                            <td className='bottomtd commmontext pricetttxt'>{data.price}</td>
                                            <td className='bottomtd commmontext'>
                                                <div>
                                                    <Counter />
                                                    Add
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                    {selectedCategory === "Transparent Tape" && (
                        <div className='cattable_main'>
                            <Table responsive className='tablees text-center'>
                                <thead>
                                    <tr>
                                        {tabheading.map((heading, index) => (
                                            <th
                                                className={`headdingss ${index === 0 ? 'first-heading' : ''} ${index === tabheading.length - 1 ? 'last-heading' : ''}`}
                                                key={index}
                                            >
                                                {heading.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tabledata2.map((data, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className='imggdiv'>
                                                    <img src={data.image} className='tapeimgg' alt="Product" />
                                                    <p className='smalltexxx mb-0'>{data.name}</p>
                                                </div>
                                            </td>
                                            <td className='bottomtd commmontext'>{data.width}"<span className='smalltexxx'> inches</span></td>
                                            <td className='bottomtd commmontext'>{data.length}</td>
                                            <td className='bottomtd commmontext'>{data.thickness}</td>
                                            <td className='bottomtd commmontext'>{data.colours}</td>
                                            <td className='bottomtd commmontext'>{data.units}</td>
                                            <td className='bottomtd commmontext'>{data.weights}</td>
                                            <td className={`bottomtd commontext ${data.stock === 'In stock' ? 'greencol' : data.stock === 'out of stock' ? 'redcol' : ''}`}>
                                                {data.stock}
                                            </td>

                                            <td className='bottomtd commmontext pricetttxt'>{data.price}</td>
                                            <td className='bottomtd commmontext'>
                                                <div>
                                                    <Counter />
                                                    Add
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
            </Container>
        </section>
    );
}

export default Categorytable;
