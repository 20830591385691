import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import Form from "react-bootstrap/Form";
import "./TrackOrder.css";
import Common_Button from "../CommonComponent/Button_Common/Button_Common";
import {ProgressBar, Step} from "react-step-progress-bar";
const TrackOrder = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Track Order", link: "", className: "active" },
  ]);

  const [stepPercentage, setStepPercentage] = useState(0);
  const [showForm, setShowForm] = useState(true);

  const handleFormSubmit = () => {
    // Add form submission logic here if needed

    // Hide the form after 300ms (adjust timing as needed)
    setTimeout(() => {
      setShowForm(false);
    }, 300);
  };

  return (
    <>
      <section className="TrackOrder">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="track-order">
            <img
              src={process.env.PUBLIC_URL + "/assests/Banner/order.png"}
              className="order"
              alt="Order Banner"
            />
            <div className="content">
              <Row>
                <Col xxl={6} xl={6} lg={6} md={12} className="main-content-holder">
                  <div className="main-content">
                    <h1 className="order-title">
                      Track<br></br> Your Order
                    </h1>
                    <h2 className="sub-title-order sub-title-order mt-md-4 mt-3">Have an order? </h2>
                    <h2 className="sub-title-order">
                      Want to know where your order is now?
                    </h2>
                  </div>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={12}>
                  {!showForm ? (
                   

                    <div className={`Track-Code  flip-vertical-right ${showForm ? ' flip-vertical-right' : ''}`}>
                      <h4 className="code-title">Order Details</h4>
                      <div className="stepper-div">
                        <ProgressBar percent={stepPercentage}>
                          <Step>
                            {({ accomplished, index }) => (
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : ""
                                }`}
                              >
                                <div className="range-text-div">
                                  <h6 className="mini-text">
                                    Order Confirmed on Mon, 23 July
                                  </h6>
                                </div>
                              </div>
                            )}
                          </Step>
                          <Step>
                            {({ accomplished, index }) => (
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : ""
                                }`}
                              >
                                <div className="range-text-div">
                                  <h6 className="mini-text">
                                    Order Shipped on Sat, 25 July
                                  </h6>
                                </div>
                              </div>
                            )}
                          </Step>
                          <Step>
                            {({ accomplished, index }) => (
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : ""
                                }`}
                              >
                                <div className="range-text-div">
                                  <h6 className="mini-text">
                                    Order Out for Delivery on Tues, 30 July
                                  </h6>
                                </div>
                              </div>
                            )}
                          </Step>
                          <Step>
                            {({ accomplished, index }) => (
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : ""
                                }`}
                              >
                                <div className="range-text-div">
                                  <h6 className="mini-text">
                                    Order Delivered On Fri, 3 August
                                  </h6>
                                </div>
                              </div>
                            )}
                          </Step>
                        </ProgressBar>
                      </div>
                    </div>
                  ) : (
                    <div className={`Track-Code  ${!showForm ? 'flip-vertical-right ' : ''}`}>
                      <h4 className="code-title">
                        Enter the track code of your order
                      </h4>
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Order</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="For eg. Order id 8946452362"
                          />
                        </Form.Group>
                        <h4 className="product">
                        Know the progress of your product delivery.
                      </h4>
                        <div className="submit">
                          <Common_Button
                            text="Submit"
                            className="submit text-p"
                            type="button" // Change type to button
                            onClick={handleFormSubmit} // Handle onClick event
                          />
                        </div>
                      </Form>
                  
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="image-overlay"></div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default TrackOrder;
