

import React, { useEffect, useState } from "react";
import "./Register.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import Successfull_Modal from "../../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";

function Register(props) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("first");
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  useEffect(() => {
    if (modalShow) {
      const timer = setTimeout(() => {
        setModalShow(false);
        navigate("/login");
      }, 3000); // Navigate after 3 seconds

      // Clean up timer if component unmounts or modalShow changes
      return () => clearTimeout(timer);
    }
  }, [modalShow, navigate]);



  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };



  return (
    <>
      <div className="Modal-holder">
        <div className="LogIn">
          <div className="row bg-color me-0 ms-0 justify-content-center LogIn-holder">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8">
              <div className="row justify-content-center">
                <div className="col-xl-12 col-md-12 col-sm-12">
                  <div className="log_in_form register-form">
                    <div className="login-box-button ">
                      <p>Register</p>
                    </div>
                    <div className="Main-register">
                      <Form className="check-box-holder">
                        <Form.Check
                          type="radio"
                          name="payment"
                          label="B2C Customer"
                          checked={selectedOption === "first"}
                          onChange={() => setSelectedOption("first")}
                        />
                        <Form.Check
                          type="radio"
                          name="payment"
                          label="B2B Customer"
                          checked={selectedOption === "second"}
                          onChange={() => setSelectedOption("second")}
                        />
                      </Form>
                      <div className="border-regis"></div>

                      {selectedOption === "first" && (
                        <div className="b2c-customer form-field-sec-holder" >
                        <Form className="second-main "  >
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label className="small-text">
                                Full Name
                              </Form.Label>
                              <Form.Control
                                type="name"
                                placeholder="Enter Name"
                              />
                            </Form.Group>
                       
                          <Row>
                            <Col xxl={6} xl={6} lg={6} md={6}>
                              <Form.Group className="mb-3" controlId="">
                                <Form.Label className="small-text">
                                  Email
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                />
                              </Form.Group>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6}>
                              <Form.Group className="mb-3" controlId="">
                                <Form.Label className="small-text">
                                  Mobile Number
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Mobile Number"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col xxl={6} xl={6} lg={6}>
                              <Form.Group className="mb-3" controlId="">
                                <Form.Label className="small-text">
                                  Password
                                </Form.Label>
                                <div className="password-field-holder">
                                  <Form.Control
                                    type={passwordVisible ? "text" : "password"}
                                    placeholder="Password"
                                  />
                                  <div
                                    className="eye-icon-holder"
                                    onClick={togglePasswordVisibility}
                                  >
                                    <FontAwesomeIcon
                                      className="eye-icon"
                                      icon={
                                        passwordVisible ? faEye : faEyeSlash
                                      }
                                    />
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col xxl={6} xl={6} lg={6}>
                              <Form.Group className="mb-3" controlId="">
                                <Form.Label className="small-text">
                                  Confirm Password
                                </Form.Label>
                                <div className="password-field-holder">
                                  <Form.Control
                                    type={
                                      confirmPasswordVisible
                                        ? "text"
                                        : "password"
                                    }
                                    placeholder="Confirm Password"
                                  />
                                  <div
                                    className="eye-icon-holder"
                                    onClick={toggleConfirmPasswordVisibility}
                                  >
                                    <FontAwesomeIcon
                                      className="eye-icon"
                                      icon={
                                        confirmPasswordVisible
                                          ? faEye
                                          : faEyeSlash
                                      }
                                    />
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                          </Form>
                          <div className="create">
                            <Button_Common
                              onClick={() => setModalShow(true)}
                              className=" button button--webcoderskull button--border-thick button--text-medium login-btn sub-title create orange"
                              text="Create"
                            />
                          </div>

                          <div className="create_account">
                            <div className="login_text">
                              <p>
                                <Link to={"/register"}>
                                  <span className="sub-text">
                                    Already have an account?{" "}
                                    <Link to={"/login"}>
                                      <span className="sub-text open">
                                        Open account
                                      </span>
                                    </Link>
                                  </span>
                                </Link>
                              </p>
                            </div>
                          </div>

                          <div className="log_in_with">
                            <p>or Login with social accounts</p>
                          </div>

                          <div className="social_img_holder">
                            <img
                              className="social_img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assests/login/google-img.png"
                              }
                              alt="social_img"
                            />
                          </div>
                          <Link to="/">
                            <div className="back_to_span">
                              <p>Back to Home {">"}</p>
                            </div>
                          </Link>
                        </div>
                      )}

                      {selectedOption === "second" && (
                        <div className="b2b-customer form-field-sec-holder "   >
                          <Form className="second-main" >
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label className="small-text">
                                Full Name
                              </Form.Label>
                              <Form.Control
                                type="name"
                                placeholder="Enter Name"
                              />
                            </Form.Group>

                            <Row>
                              <Col xxl={6} xl={6} lg={6} md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label className="small-text">
                                    Email
                                  </Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Email"
                                  />
                                </Form.Group>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label className="small-text">
                                    Mobile Number
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder=" Mobile Number"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col xxl={6} xl={6} lg={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="password"
                                >
                                  <Form.Label className="small-text">
                                    Password
                                  </Form.Label>
                                  <div className="password-field-holder">
                                    <Form.Control
                                      type={
                                        passwordVisible ? "text" : "password"
                                      }
                                      placeholder="Password"
                                    />
                                    <div
                                      className="eye-icon-holder"
                                      onClick={togglePasswordVisibility}
                                    >
                                      <FontAwesomeIcon
                                        className="eye-icon"
                                        icon={
                                          passwordVisible ? faEye : faEyeSlash
                                        }
                                      />
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col xxl={6} xl={6} lg={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="confirmPassword"
                                >
                                  <Form.Label className="small-text">
                                    Confirm Password
                                  </Form.Label>
                                  <div className="password-field-holder">
                                    <Form.Control
                                      type={
                                        confirmPasswordVisible
                                          ? "text"
                                          : "password"
                                      }
                                      placeholder="Password"
                                    />
                                    <div
                                      className="eye-icon-holder"
                                      onClick={toggleConfirmPasswordVisibility}
                                    >
                                      <FontAwesomeIcon
                                        className="eye-icon"
                                        icon={
                                          confirmPasswordVisible
                                            ? faEye
                                            : faEyeSlash
                                        }
                                      />
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label className="small-text">
                                Company Name
                              </Form.Label>
                              <Form.Control
                                type="name"
                                placeholder="Company Name"
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="">
                              <Form.Label className="small-text">
                                GST Number
                              </Form.Label>
                              <Form.Control
                                type="name"
                                placeholder="GST Number"
                              />
                            </Form.Group>
                          </Form>
                          <div className="create">
                            <Button_Common
                              onClick={() => setModalShow(true)}
                              className=" button button--webcoderskull button--border-thick button--text-medium login-btn sub-title create orange"
                              text="Create"
                            />
                          </div>

                          <div className="create_account ">
                            <div className="login_text">
                              <p>
                                <Link to={"/register"}>
                                  <span className="sub-text">
                                    Already have an account?{" "}
                                    <Link to={"/login"}>
                                      <span className="sub-text open">
                                        Open account
                                      </span>
                                    </Link>
                                  </span>
                                </Link>
                              </p>
                            </div>
                          </div>

                          <div className="log_in_with">
                            <p>or Login with social accounts</p>
                          </div>

                          <div className="social_img_holder">
                            <img
                              className="social_img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assests/login/google-img.png"
                              }
                              alt="social_img"
                            />
                          </div>
                          <Link to="/">
                            <div className="back_to_span">
                              <p>Back to Home {">"}</p>
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Successfull_Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          message="Account Created Successfully!"
        />
      </div>
    </>
  );
}

export default Register;
