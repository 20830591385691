import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
// import Permision_Delete_Modal from "../CommonComponents/Common_Modal/Permision_Delete_Modal/Permision_Delete_Modal";
import { useNavigate } from "react-router-dom";
import Permision_Delete_Modal from "../CommonComponent/Common_Modal/Permision_Delete_Modal/Permision_Delete_Modal";
import LogOut from "../CommonComponent/Common_Modal/LogOut/LogOut";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";

const ConfirmOrder = ({ handlePrevious, handleNext }) => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [ordermodalclose, setOrdermodalclose] = useState(false);
    const [deletepermissionShow, setdeletepermissionShow] = React.useState(false);

    const navigateClick = () => {
        setOrdermodalclose(true);
        setTimeout(() => {
            setOrdermodalclose(false);
            navigate("/order");
        }, 3000)
    }
    const orders = [
        {
            image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
            name: "Brown Tape",
            text: "2 40 Meter,38 Micron, Per Box 72 Nos",
            quantity: "1",
            price: "₹.408",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
            name: "Brown Tape",
            text: "2 40 Meter,38 Micron, Per Box 72 Nos",
            quantity: "1",
            price: "₹.408",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
            name: "Brown Tape",
            text: "2 40 Meter,38 Micron, Per Box 72 Nos",
            quantity: "1",
            price: "₹.408",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
            name: "Brown Tape",
            text: "2 40 Meter,38 Micron, Per Box 72 Nos",
            quantity: "1",
            price: "₹.408",
        },
    ];

    const handlePlaceOrder = () => {
        setModalShow(true);

        setTimeout(() => {
            setModalShow(false);
            navigate("/your-order");
        }, 2000);
    };

    return (
        <>
            <section className="OrderReview">
                <div className="Address">
                    <Form.Check type="checkbox" label="Select All" />
                    <Row className="justify-content-center mt-2">
                        <Col xxl={8} xl={8} lg={12} md={12}>
                            {orders.map((item, index) => (
                                <div className="Deatils-address" key={index}>
                                    <Form.Check type="checkbox" />
                                    <div className="OrderDetails">
                                        <div className="Details">
                                            <Row>
                                                <Col xxl={3} xl={3} lg={3} md={3} xs={12} sm={12}>
                                                    <div className="main-cart">
                                                        <div className="image-product">
                                                            <img
                                                                src={item.image}
                                                                alt="product"
                                                                className="payment-logo"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={7} xl={8} lg={7} md={7} xs={12} sm={12}>
                                                    <div className="content">
                                                        <Row>
                                                            <p className="commmontext">{item.name}</p>

                                                            <p className="small-text">{item.text}</p>

                                                            <div className="Quantity">
                                                                <p className="small-text">QTY</p>
                                                                <div className="quantity-no sub-title">
                                                                    {item.quantity}
                                                                </div>
                                                            </div>

                                                            <p className="price commmontext">{item.price}</p>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={1} lg={2} md={1} xs={12} sm={12}>
                                                    <div className="Delete-order">
                                                        <div className="delete-main">
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="icon"
                                                                onClick={() => setdeletepermissionShow(true)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Col>
                        <Col xxl={4} xl={4} lg={7} md={10}>
                            <div className="Order-payment">
                                <div className="total-amount">
                                    <p className="sub-title">Order Summary</p>
                                    <Row>
                                        <div className="order-main">
                                            <p className="small-text">Price Item(3):</p>
                                            <p className="small-text">₹. 9,990.00</p>
                                        </div>
                                        <div className="order-main">
                                            <p className="small-text">Discount:</p>
                                            <p className="small-text">-₹.7877</p>
                                        </div>
                                        <div className="order-main">
                                            <p className="small-text">Payment Method:</p>
                                            <p className="small-text">Online</p>
                                        </div>

                                        <div className="order-main">
                                            <p className="small-text">Shipping</p>

                                            <p className="small-text">₹0</p>
                                        </div>
                                        <div className="order-main">
                                            <p className="small-text">Name of address</p>

                                            <p className="small-text add">
                                                Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016
                                            </p>
                                        </div>

                                        <div className="order-main">
                                            <p className="price-text sub-title">Total</p>

                                            <p className="price-text sub-title">₹9760</p>
                                        </div>
                                    </Row>
                                    <div className="apply_now_btn order">
                                        <Button_Common
                                            onClick={handlePrevious}
                                            className="back-btn me-3 small-text back"
                                            text="BACK"
                                        >
                                            Back
                                        </Button_Common>

                                        <Button_Common
                                            type="button"
                                            onClick={navigateClick}
                                            className="tabs-btn small-text orange"
                                            text="Confirm Order"
                                        >
                                            Next
                                        </Button_Common>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <LogOut show={deletepermissionShow} onHide={() => setdeletepermissionShow(false)}  />


            <Successfull_Modal show={ordermodalclose} onHide={() => setOrdermodalclose(false)} message={"Your Order has been Placed Successfuly"} />
        </>
    );
};

export default ConfirmOrder;
