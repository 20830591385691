import React, { useEffect, useState } from "react";
import "./HotDeals.css";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import ProductCardSale from "../CommonComponent/ProductCardSale/ProductCardSale";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

function HotDeals() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Hot Deals", link: "" },
    ,
  ]);

  const [showMore, setShowMore] = useState(false);

  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const product = [
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
      title: "Regular Transparent Tape",
      size: "Size: 24mm x 66m",
      saleprice: "₹ 1,234.00",
      realprice: "₹ 1,234.00",
    },
  ];

  const visibleProducts = showMore ? product : product.slice(0, 6);
  return (
    <>
      <section className="hoteals_section">
        <Container>
          <div className="hotdeals_maindiv">
            <div>
              <Breadcrumbs items={breadcrumbItems} />
            </div>
            <div className="topplate_div mb-4">
              <h4 className="dealtitle merriweather-bold mb-0">Hot Deals</h4>
              <p
                className="showtext mb-0 text-p"
                onClick={() => setShowMore(!showMore)}
              >
                {" "}
                {showMore ? "Show Less" : "Show More"}{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="angleiconn ms-1  "
                />
              </p>
            </div>

            {!showMore && (
              <div className="swipe_div_mainss">
                <Swiper
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    486: {
                      slidesPerView: 1.5,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 2.8,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    1400: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                    1900: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                  }}
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {visibleProducts.map((item, index) => (
                    <SwiperSlide key={index}>
                      
                        <ProductCardSale
                          image={item.image}
                          title={item.title}
                          size={item.size}
                          saleprice={item.saleprice}
                          realprice={item.realprice}
                        />
                    
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="swiperoverlay">
                  <div
                    className="back-btn"
                    onClick={() => handleMainSliderClick("prev")}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                  </div>
                </div>
                <div className="swiperoverlay2">
                  <div
                    className="next-btn"
                    onClick={() => handleMainSliderClick("next")}
                  >
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="icon-main"
                    />
                  </div>
                </div>
              </div>
            )}
            {showMore && (
              <div>
                <Row>
                  {visibleProducts.map((item, index) => (
                    <Col xxl={3} xl={4} lg={6} md={6} sm={12} className="mb-3">
                      <div>
                        <ProductCardSale
                          image={item.image}
                          title={item.title}
                          size={item.size}
                          saleprice={item.saleprice}
                          realprice={item.realprice}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </div>
        </Container>
      </section>
    </>
  );
}

export default HotDeals;
