import React, { useState } from "react";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import Breadcrumbs from "../../components/CommonComponent/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNoteSticky,
  faTruckFast,
  faBox,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SelectOption from "../CommonComponent/Common_Modal/SelectOption/SelectOption";
import { Link } from "react-router-dom";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
const ReturnOrder = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "My account", link: "" },
    { text: "Order", link: "/yourOrders" },
    { text: "Cancel Order", link: "", className: "active" },
  ]);
  const [modalSuccessShow, setModalSuccessShow] = React.useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Only the first step is completed
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <section className="Order">
        <Container>
          <Breadcrumbs items={breadcrumbItems} />
          <div className="Main-Order-Card">
            <Row>
              {/* <Col xxl={3} xl={3} lg={3} md={12}>
                <div className="main-back">
                  <div className="image-main">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assests/Products/Tape2.png"
                      }
                      className="tape"
                    />
                  </div>
                </div>
              </Col> */}
              <Col xxl={12} xl={12} lg={12} md={12}>
                <div className="Content">
                  <div className="order-content">
                    <div className="Content-main">
                      <h4 className="commmontext">Brown Tape</h4>
                      <p className="small-text">
                        2" 40 Meter, 38 Micron, Per Box 72 Nos
                      </p>
                      <p className="small-text">Order Number: 0005-12122023</p>
                      <p className="small-text">Payment: Online</p>
                      <div className="price-main">
                        <p className="commmontext price me-4">Total: ₹3000</p>
                        <p className="main-price">
                          ₹ 1,234.00 <br />
                          (Inclusive of all taxes)
                        </p>
                      </div>
                      <p className="small-text mt-2">
                        Total Additional Charges: ₹ 50
                      </p>
                    </div>
                    <div className="view">
                      <Link to={"/b2bbulkorder"}>
                        <p className="small-text">View Product</p>
                      </Link>
                    </div>
                  </div>

                  <div className="steps">
                    {[
                      {
                        step: 1,
                        name: "Order Placed",
                        icon: faNoteSticky,
                        date: "9 Jun, 2024, 11:45 am",
                      },
                      {
                        step: 2,
                        name: "Processing",
                        icon: faBox,
                        date: "11 Jun, 2024",
                      },
                      {
                        step: 3,
                        name: "Shipping",
                        icon: faTruckFast,
                        date: "10 Jun, 2024",
                      },
                      {
                        step: 4,
                        name: "Delivery On",
                        icon: faBox,
                        date: "11 Jun, 2024",
                      },
                    ].map(({ step, name, icon, date }) => (
                      <React.Fragment key={step}>
                        <div
                          className={`step step${step} ${
                            currentStep >= step ? "completed " : "incompleted"
                          }`}
                        >
                          <div className="step-name">
                            <div
                              className={`circle me-3 ${
                                currentStep >= step ? "yellow" : ""
                              }`}
                            >
                              <FontAwesomeIcon icon={icon} />
                            </div>
                            <p className="small-text">{name}</p>
                          </div>
                          <p className="sub">{date}</p>
                        </div>
                        {step < 4 && (
                          <div
                            className={`border-card ${
                              currentStep >= step
                                ? "border-card-before-after"
                                : "border-card-before-after-incomplete"
                            }`}
                          ></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  <div
                    className="cancellation"
                    onClick={() => setModalShow(true)}
                  >
                    <div className="Main-cancel">
                      <p className="commmontext">
                      Select a reason for cancellation
                      </p>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                  </div>
                  <div className="text-area">
                    <p className="small-text">
                    Could you tell us a reason for cancellation? (optional)
                    </p>
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          label="I dont Want to Buy"
                          placeholder="type a reason for cancellation "
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="Btn-Cancel">
                    <Link to={"/order"}>
                      <Button_Common
                        text="Cancel"
                        className="cancel me-5 small-text"
                      />
                    </Link>
                    <Button_Common
                      text="Submit"
                      className="submit small-text"
                      onClick={() => setModalSuccessShow(true)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <SelectOption show={modalShow} onHide={() => setModalShow(false)} />
        <Successfull_Modal
          show={modalSuccessShow}
          onHide={() => setModalSuccessShow(false)}
          message={"Your Order Cancel Successfully!"}
        />
      </section>
    </>
  );
};

export default ReturnOrder;
