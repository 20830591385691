import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import OTP_Modal from "../OTP_Modal/OTP_Modal";

function Forgot_Password(props) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <div className="Modal-holder">
                <div className="LogIn">
                    <Container>
                        <div className="row bg-color me-0 ms-0 justify-content-center LogIn-holder">
                            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7">
                                <div className="row justify-content-center">
                                    <div className="col-xl-12 col-lg-12">
                                        <div className="log_in_form">
                                            <div className="Main-starting">
                                                <div className="login-box-button">
                                                    <p className="commmontext">Reset Your Password</p>
                                                </div>

                                                <div className="text-holder">
                                                    <p className="note-text sub-title">
                                                        You will receive an email from us requesting you to Reset your
                                                        password.
                                                    </p>
                                                </div>

                                                <Form>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label className="small-text">
                                                            Email / Mobile Number
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="phone"
                                                            placeholder="Email / Mobile Number"
                                                        />
                                                    </Form.Group>

                                                    <div className="btn-holder">
                                                        <Link to="/">
                                                            <Common_Button
                                                                className="button button--webcoderskull button--border-thick button--text-medium cancle-btn sub-title"
                                                                text="Cancel"
                                                            />
                                                        </Link>
                                                        <Link to="/otp">
                                                        <Common_Button
                                                          
                                                            className="button button--webcoderskull button--border-thick button--text-medium login-btn sub-title submit orange"
                                                            text="Submit"
                                                        />
                                                        </Link>
                                                    </div>
                                                </Form>

                                                <Link to="/">
                                                    <div className="back_to_span">
                                                        <p>
                                                            Back to Home
                                                            <FontAwesomeIcon
                                                                icon={faAngleRight}
                                                                className="back-arrow"
                                                            />
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
             
                    </Container>
                </div>
            </div>
     
        </>
    );
}

export default Forgot_Password;
