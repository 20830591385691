import React from "react";
import "./WhyUs.css";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper/modules";

const WhyUs = () => {
    const banners = [
        {
            image1: process.env.PUBLIC_URL + "/assests/ProductDetails/whyus.png",
            image2: process.env.PUBLIC_URL + "/assests/ProductDetails/whyus.jpg_2.png",
        },
        {
            image1: process.env.PUBLIC_URL + "/assests/ProductDetails/whyus.png",
            image2: process.env.PUBLIC_URL + "/assests/ProductDetails/whyus.jpg_2.png",
        },
        {
            image1: process.env.PUBLIC_URL + "/assests/ProductDetails/whyus.png",
            image2: process.env.PUBLIC_URL + "/assests/ProductDetails/whyus.jpg_2.png",
        },
    ];
    return (
        <>
            <section className="WhyUs">
                <Container>
                    <div className="why-us-title">
                        <p className="explore-btn">Why Us?</p>
                    </div>

                    <Swiper
                        pagination={{
                            dynamicBullets: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {banners.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Row>
                                    <Col xxl={6} xl={6} lg={6} md={6}>
                                        <div className="why-us-banner">
                                            <img src={item.image1} className="why-image" />
                                        </div>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} md={6}>
                                        <div className="why-us-banner">
                                            <img src={item.image2} className="why-image" />
                                        </div>
                                    </Col>
                                </Row>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </Container>
            </section>
        </>
    );
};

export default WhyUs;
