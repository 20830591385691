import React, { useEffect } from "react";
import "./App.css";
import "./index.css";
import { Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import HomePage from "./components/HomePage/HomePage";
import ProductDetailPage from "./components/ProductDetailPage/ProductDetailPage";
import Register_Pages from "./components/Register_Pages/RegisterMain/RegisterMain";
import B2BBulkOrder from "./components/B2BBulkOrder/B2BBulkOrder";
import FAQ from "./components/FAQ/FAQ";
import TrackOrder from "./components/TrackOrder/TrackOrder";
// import Footer from "./components/footer/Footer";
import HotDeals from "./components/HotDeals/HotDeals";
import Help from "./components/Help/Help";
import ContactUs from "./components/ContactUs/ContactUs";
import CheckoutProcess from "./components/CheckoutProcess/CheckoutProcess";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import CancelPolicy from "./components/CancelPolicy/CancelPolicy";
import ReturnPolicy from "./components/ReturnPolicy/ReturnPolicy";
import ShippingPolicy from "./components/ShippingPolicy/ShippingPolicy";
import Payment_Method from "./components/Payment_Method/Payment_Method";
import Security_Trust from "./components/Security_Trust/Security_Trust";
import Terms_And_Condition from "./components/Terms_And_Condition/Terms_And_Condition";
import CustomizeTape from "./components/CustomizeTape/CustomizeTape";
import CmjIndustry from "./components/CmjIndustry/CmjIndustry";
import CartMain from "./components/CartMain/CartMain";
import Voucher from "./components/Voucher/Voucher";
import ReturnOrder from "./components/ReturnOrder/ReturnOrder";
import Order from "./components/Order/Order";
import Login from "./components/Login_Pages/Login/Login";
import Register from "./components/Login_Pages/Register/Register";
import Profile from "./components/Profile/Profile";
import Footer from "./components/Footer/Footer";
import CompareList from "./components/CompareList/CompareList";
import Wishlist from "./components/WishList/Wishlist";
import Header from "./components/header/Header";
import Start_Shopping from "./components/Start_Shopping/Start_Shopping";
import Forgot_Password from "./components/Login_Pages/Forgot_Password/Forgot_Password";
import Cancleorder from "./components/Cancleorder/Cancleorder";
import OTP_Modal from "./components/Login_Pages/OTP_Modal/OTP_Modal";
import Set_New_Password from "./components/Login_Pages/Set_New_Password/Set_New_Password";
import PostSubmission from "./components/CommonComponent/Common_Modal/PostSubmission/PostSubmission";
import YourOrders from "./components/YourOrders/YourOrders";
import View_Order_Details from "./components/YourOrders/View_Order_Details/View_Order_Details";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const location = useLocation();
  const hideHeaderFooter = [
    "/login",
    "/register",
    "/set-new-password",
    "/forgot-password",
    "/otp",
  ].includes(location.pathname);

  return (
    <>
      <ScrollToTop />
      {!hideHeaderFooter && <Header />}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/sample-roll" element={<ProductDetailPage />} />
        <Route path="/b2bbulkorder" element={<B2BBulkOrder />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/track-order" element={<TrackOrder />} />
        <Route path="/hotdeals" element={<HotDeals />} />
        <Route path="/help" element={<Help />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/checkout" element={<CheckoutProcess />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/Cancellationpolicy" element={<CancelPolicy />} />
        <Route path="/return&exchangepolicy" element={<ReturnPolicy />} />
        <Route path="/shippingpolicy" element={<ShippingPolicy />} />
        <Route path="/payment-method" element={<Payment_Method />} />
        <Route path="/security-trust" element={<Security_Trust />} />
        <Route path="/terms-and-condition" element={<Terms_And_Condition />} />
        <Route path="/cmj-industries" element={<CmjIndustry />} />
        <Route path="/cart" element={<CartMain />} />
        <Route path="/login" element={<Login />} />
        <Route path="/voucher" element={<Voucher />} />
        <Route path="/return-order" element={<ReturnOrder />} />
        <Route path="/cancel-order" element={<Cancleorder />} />
        <Route path="/start-shopping" element={<Start_Shopping />} />
        <Route path="/order" element={<Order />} />
        <Route path="/forgot-password" element={<Forgot_Password />} />
        <Route path="/set-new-password" element={<Set_New_Password />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/comparelist" element={<CompareList />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/otp" element={<OTP_Modal/>} />
        <Route path="/customize-tape" element={<CustomizeTape/>} />
        {/* <Route path="/post-submission" element={<PostSubmission/>} /> */}
        <Route path="/yourOrders" element={<YourOrders />} />
        <Route path="/view-order-details" element={<View_Order_Details />} />
      </Routes>

      {!hideHeaderFooter && <Footer />}
    </>
  );
}

export default App;
