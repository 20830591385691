import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../../../components/CommonComponent/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky, faTruckFast, faBox, faDownload } from "@fortawesome/free-solid-svg-icons";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
import SelectOption from "../../CommonComponent/Common_Modal/SelectOption/SelectOption";
import PdfMain from "../../CommonComponent/Common_Modal/PdfMain/PdfMain";

const Return = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "My account", link: "" },
        { text: "Return", link: "", className: "active" },
    ]);
    const [currentStep, setCurrentStep] = useState(1); // Only the first step is completed
    const [modalShow, setModalShow] = useState(false);
    const [modalCancleShow, setModalCancleShow] = useState(false);

    return (
        <>
            <section className="Order">
                <Container>
                    <Breadcrumbs items={breadcrumbItems} />
                    <div className="Main-Order-Card">
                        <Row>
                            {/* <Col xxl={3} xl={3} lg={3} md={12}>
                                <div className="main-back">
                                    <div className="image-main">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Products/Tape2.png"}
                                            className="tape"
                                        />
                                    </div>
                                </div>
                            </Col> */}
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div className="Content">
                                    <div className="order-content">
                                        <div className="Content-main">
                                            <h4 className="commmontext">Brown Tape</h4>
                                            <p className="small-text">2" 40 Meter, 38 Micron, Per Box 72 Nos</p>
                                            <p className="small-text">Order Number: 0005-12122023</p>
                                            <p className="small-text">Payment: Online</p>
                                            <div className="price-main">
                                                <p className="commmontext price me-4">Total: ₹3000</p>
                                                <p className="main-price">
                                                <span className="rate-price">₹ 1,234.00 </span> <br />
                                                    (Inclusive of all taxes)
                                                </p>
                                            </div>
                                            <p className="small-text mt-2">Total Additional Charges: ₹ 50</p>
                                        </div>
                                        <div className="view">
                                            <Link to={"/b2bbulkorder"}>
                                                <p className="small-text">View Product</p>
                                            </Link>

                                            <Link to={"/view-order-details"}>
                                                <p className="small-text">View Order details</p>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="steps mt-2">
                                        {[
                                            { step: 1, name: "Order Placed", icon: faNoteSticky, date: "9 Jun, 2024, 11:45 am" },
                                            { step: 2, name: "Processing", icon: faBox, date: "11 Jun, 2024" },
                                            { step: 3, name: "Shipping", icon: faTruckFast, date: "10 Jun, 2024" },
                                            { step: 4, name: "Delivery On", icon: faBox, date: "11 Jun, 2024" }
                                        ].map(({ step, name, icon, date }) => (
                                            <React.Fragment key={step}>
                                                <div className={`step step${step} ${currentStep >= step ? "completed " : "incompleted"}`}>
                                                    <div className="step-name">
                                                        <div className={`circle me-3 ${currentStep >= step ? "circle-fill" : ""}`}>
                                                            <FontAwesomeIcon icon={icon} />
                                                        </div>
                                                        <p className="small-text">{name}</p>
                                                    </div>
                                                    <p className="sub">{date}</p>
                                                </div>
                                                {step < 4 && (
                                                    <div className={`border-card ${currentStep >= step ? "border-card-before-after" : "border-card-before-after-incomplete"}`}></div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div className="Btn-main">
                                     
                                        <Button_Common
                                            onClick={() => setModalShow(true)}
                                            text="Download Invoice"
                                            icon={faDownload}
                                            className=" return small-text"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="Delivered mt-1">
                        <p className="sub-title red">Return by July 2024</p>
                        <div className="rate">
                        <p className="sub-title r mb-0 mt-1">Rate Us:</p>
                            <Rating />
                        </div>
                    </div>
                </Container>
            </section>
            <PdfMain show={modalShow} onHide={() => setModalShow(false)} />
            <SelectOption show={modalCancleShow} onHide={() => setModalCancleShow(false)} />
        </>
    );
};

export default Return;
