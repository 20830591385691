import React from "react";
import "./PeopleSays.css";
import {Col, Container, Row} from "react-bootstrap";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {Pagination} from "swiper/modules";
function PeopleSays() {
    const cardsDetails = [
        {
            title: "Free door-to-door shipping",
            text: "With a short period of time",
            image: process.env.PUBLIC_URL + "/assets/images/About/Component1.png",
        },
        {
            title: "24/7 Support",
            text: "Ready to pickup calls",
            image: process.env.PUBLIC_URL + "/assets/images/About/Component2.png",
        },
        {
            title: "Secure transaction",
            text: "Checkout securely",
            image: process.env.PUBLIC_URL + "/assets/images/About/Component1.png",
        },
        {
            title: "B2B Bulk Order Available",
            text: "On many products",
            image: process.env.PUBLIC_URL + "/assets/images/About/Component2.png",
        },
        {
            title: "B2B Bulk Order Available",
            text: "On many products",
            image: process.env.PUBLIC_URL + "/assets/images/About/Component1.png",
        },
    ];
    return (
        <>
            <section className="peoplesay_section">
                <Container>
                    <div className="peoplesay_main">
                        <Row>
                            <Col xxl={10} xl={10} lg={11} md={12} className="mx-auto">
                                <div className="peoplewipe_div">
                                    <div className=" titlle_div mx-auto text-center">
                                        <h2 className="whatpeptitle merriweather-bold">
                                            <span className="borderdiv"> What Pe</span>ople Says
                                        </h2>
                                    </div>
                                    <div className="mb-2">
                                        <img
                                            className="quammaimgg"
                                            src={process.env.PUBLIC_URL + "/assests/HomePage/PeopleSays/envert1.png"}
                                        />
                                    </div>
                                    <div className="swiperrrdiv">
                                        <Swiper
                                            pagination={{
                                                dynamicBullets: true,
                                            }}
                                            modules={[Pagination]}
                                            className="peoplesay-mySwiper"
                                        >
                                            {cardsDetails.map((item, index) => (
                                                <SwiperSlide>
                                                    <div className="mainndiv">
                                                        <Row>
                                                            <Col xxl={4} xl={4} lg={5} md={6}>
                                                                <div className="img_div">
                                                                    <div className="imgmain">
                                                                        <img
                                                                            className="swipeimgg"
                                                                            src={
                                                                                process.env.PUBLIC_URL +
                                                                                "/assests/HomePage/PeopleSays/swipimg1.png"
                                                                            }
                                                                        />
                                                                        <div className="imgsback"></div>{" "}
                                                                    </div>{" "}
                                                                </div>
                                                            </Col>
                                                            <Col xxl={8} xl={8} lg={7} md={6}>
                                                                <div className="text_div">
                                                                    <p className="nametext infotext">Rahul Dravid</p>
                                                                    <p className="nametext ">
                                                                        Lorem ipsum dolor sit amet, consectetuer
                                                                        adipiscing elit. Aenean commodo ligula eget
                                                                        dolor. Aenean massa. Cum sociis natoque
                                                                        penatibus et magnis dis parturient montes,
                                                                        nascetur ridiculus mus. Donec quam felis,
                                                                        ultricies nec, pellentesque eu, pretium quis,
                                                                        sem. Nulla consequat massa quis enim. Donec pede
                                                                        justo, fringilla vel, aliquet nec, vulputate
                                                                        eget, arcu. In enim justo, rhoncus ut, imperdiet
                                                                        a, venenatis vitae, justo. Nullam dictum felis
                                                                        eu pede mollis pretium. Integer tincidunt. Cras
                                                                        dapibus. Vivamus elementu
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                    <div className="mt-2 mb-3  pe-3 text-end">
                                        <img
                                            className="quammaimgg"
                                            src={process.env.PUBLIC_URL + "/assests/HomePage/PeopleSays/envert2.png"}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default PeopleSays;
