import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import Button_Commom from "../CommonComponent/Button_Common/Button_Common";
import "./Profile.css";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
const Profile = () => {
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        // Save functionality here
        setIsEditing(false);
    };
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <section className="Profile">
                <Container>
                    <Row className="justify-content-center">
                        <Col xxl={8} xl={8} lg={8} md={8}>
                            <div className="Profile-Mail">
                                <div className="icon" onClick={handleEditClick}>
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </div>
                                <div className="Profile-Form">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="sub-title">Full Name</Form.Label>
                                            <Form.Control
                                                type="name"
                                                placeholder={isEditing ? "" : "Pratiksha Pethe"}
                                                className="small-text"
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Form>

                                    <Row className="Details">
                                        <Col xxl={6} xl={6} lg={6} md={6}>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="sub-title">Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder={isEditing ? "" : "Pr*******@gmail.com"}
                                                        className="small-text"
                                                        readOnly={!isEditing}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6}>
                                            <Form>
                                                <Form.Group className="no-arrows-filed mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="sub-title">Mobile Number</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder={isEditing ? "" : "+91 3455632133"}
                                                        className="small-text"
                                                        readOnly={!isEditing}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Form className="Details">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="sub-title">Password</Form.Label>
                                            <Form.Control
                                                type="name"
                                                placeholder={isEditing ? "" : "Pratiksha@123"}
                                                className="small-text"
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                            {isEditing && (
                                <div className="Save" onClick={() => setModalShow(true)}>
                                    <Button_Commom
                                        text="Save"
                                        className="save orange small-text"
                                        onClick={handleSaveClick}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
                <Successfull_Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    message="Profile Updated Successfully!"
                />
            </section>
        </>
    );
};

export default Profile;
