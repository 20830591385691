import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./OurVission.css";
const OurVission = () => {
    return (
        <>
            <section className="OurVission">
                <Container>
                    <h1 className="title-mission">Our vision And Mission</h1>
                    <Row className="justify-content-center">
                        <Col xxl={10}>
                            <div className="Content-Mission">
                                <Row>
                                    <Col xxl={5} xl={5} lg={5} md={12}>
                                        <div className="yellow-background">
                                            <div className="image-main">
                                                <img
                                                    className="vission-img"
                                                    src={process.env.PUBLIC_URL + "/assests/Cmj/Outline.png"}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={7} xl={7} lg={7} md={12}>
                                        <h1 className="title-main">
                                            <span className="main-title-border">Our Vis</span>ion
                                        </h1>
                                        <p className="sub-title mt-4">
                                            At CMJ Industries, our vision is to become the premier provider of
                                            innovative and sustainable packaging solutions across India. We strive to
                                            exceed our customers&#39; expectations through unparalleled product quality,
                                            exceptional service, and continuous improvement. We envision a future where
                                            our tapes and packaging solutions set the industry standard, fostering a
                                            seamless and secure packaging experience for businesses nationwide.
                                        </p>
                                    </Col>
                                </Row>
                                <div className="border-choose"></div>
                                <Row className="mission">
                                    <Col  xxl={7} xl={7} lg={7} md={12}>
                                        <h1 className="title-main">
                                            <span className="main-title-border">Our Mi</span>ssion
                                        </h1>
                                      
                                        <ul>
                                        <li className="sub-title mt-3">
                                        Our mission at CMJ Industries is to deliver superior packaging products that
                                            ensure the utmost reliability and efficiency for our clients. We are
                                            committed to:
                                        </li>
                                            <li className="sub-title">
                                                <span className="sub-title main-mission">1. Quality Excellence:</span> Manufacturing the highest quality BOPP tapes,
                                                transparent tapes, brown tapes, and packaging tapes using
                                                state-of-the-art technology and rigorous quality control measures.
                                            </li>
                                            <li className="sub-title ">
                                            <span className="sub-title main-mission"> 2. Customer Focus:</span> Understanding and addressing the unique needs of our
                                                customers, providing tailored solutions that enhance their operational
                                                efficiency and product safety.
                                            </li>
                                            <li className="sub-title">
                                            <span className="sub-title main-mission">3. Innovation:</span> Continuously innovating our product offerings and
                                                manufacturing processes to stay ahead of industry trends and meet the
                                                evolving demands of the market.
                                            </li>
                                            <li className="sub-title">
                                            <span className="sub-title main-mission">4. Sustainability:</span> Promoting sustainable practices in our operations,
                                                from sourcing eco- friendly materials to reducing waste, ensuring a
                                                positive impact on the environment.
                                            </li>
                                            <li className="sub-title">
                                            <span className="sub-title main-mission">5. Integrity and Transparency:</span> Conducting our business with the highest
                                                levels of integrity, honesty, and transparency, building long-term
                                                relationships based on trust and mutual respect.
                                            </li>
                                            <li className="sub-title">
                                                By adhering to these principles, we aim to be the preferred choice for
                                                packaging solutions and contribute to the success of our clients and
                                                partners throughout India.
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col xxl={5} xl={5} lg={5} md={12} >
                                    <div className="second-main" >
                                    <div className="yellow-background second">
                                            <div className="image-main-two">
                                                <img
                                                    className="vission-img"
                                                    src={process.env.PUBLIC_URL + "/assests/Cmj/Target.png"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                  
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default OurVission;
