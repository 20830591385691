import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../CommonComponent/Breadcrumbs/Breadcrumbs';
import { Container } from 'react-bootstrap'

function ReturnPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Return & Exchange Policy", link: "" },
        ,
    ]);
  return (
    <>
    <section className='privacy_section'>
        <Container>
            <div>
                <Breadcrumbs items={breadcrumbItems} />
            </div>
            <div className='Privacy_main'>
                <div className='privacybanner_div '>
                    <img className='privacybanner' src={process.env.PUBLIC_URL + "/assests/Banner/yongwomen.png"} />
                    <div className='banoverlays'>


                        <p className='bannertitle'>Return & Exchange Policy</p>
                    </div>

                </div>
                <div className='bannertext_main_div'>


                    <div className='bannertext_div mb-3'>
                        <p className='text-p '>At CMJ Industries, we take great care in packaging and shipping our products to ensure they reach you in excellent condition. However, in the unlikely event of damage during transit or receipt of a wrong product, we will gladly accept returns and offer a replacement or refund. Please note that due to the nature of our products, we do not allow returns or exchanges for any other reason.</p>
                    <p className='privecy_subtitle'>In case of damage or wrong product receipt, please follow these steps:</p>
                    <p className='privcytext ms-sm-2'>1. Contact us within 2 days from the order delivery date via email at cmjindustries13@gmail.com or call our customer service number.</p>
                    <p className='privcytext  ms-sm-2'>2. Provide us with your order number, details of the issue, and photos of the damaged product (if applicable). </p>
                    <p className='privcytext  ms-sm-2'> 3. We will investigate and respond with a resolution, which may include a replacement or refund, within 5-7 working days.</p>
                    <p className='privecy_subtitle ms-sm-3 mb-0'>Please note that:</p>
                    <p className='text-p ms-sm-2'> we will not entertain any concerns after 2 days from the order delivery date, regardless of the reason.</p>
                    
                    </div>
                    
                    
                    <div className='bannersubtext_div mb-3'>
                        <p className='privecy_subtitle'>Note:</p>
                        <p className='privcytext ms-sm-4'> Before placing a bulk order, we recommend purchasing a sample roll of our Transparent Tape or Brown Tape to test the adhesive quality and micron thickness. This will ensure you are satisfied with the product quality before making a bulk purchase. Sample rolls are available for purchase before placing any bulk order. Please note that we will not accept any reasons for returns or exchanges in case of bulk orders. </p>

                        <p className='privcytext boldtext ms-sm-4'>"All our products are of 38 Micron quality, which is preferred by many reputed companies in the pharmaceutical, automobile, and logistics industries, and they are our regular and repeated customers through bulk orders.  </p>
                        <p className='privcytext ms-sm-4'>They have consistently expressed satisfaction with our product quality and service. If you require a higher quality than 38 Micron and have a regular bulk order on a monthly basis, please contact our customer care immediately so we can provide you with the required product. Please note that you will need to clearly specify your micron requirements to our customer care team."</p>
                        <p className='privcytext boldtext ms-sm-4'> We appreciate your understanding and cooperation in this matter.</p>
                        
                        <h1 className='bigtextt'>Return & Exchange Policy</h1>


                       



                    </div>

                </div>
            </div>
        </Container>
    </section>

</>
  )
}

export default ReturnPolicy