import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./ProductDescription.css";

const ProductDescription = () => {
    return (
        <>
            <section className="ProductDescription">
                <Container>
                    <h1 className="title-main">
                        <span className="main-title-border">Product</span> Description
                    </h1>
                    <div className="text">
                        <p className="explore-btn mt-4">
                            Welcome To "CMJ INDUSTRIES". CMJ Industries is Big Brand. Pune Maharashtra Based, Since
                            2019.
                        </p>
                    </div>
                    <Row className="justify-content-center">
                        <Col xxl={10} xl={10} lg={10}>
                            <div className="description">
                                <div className="industry-main">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assests/ProductDetails/1.png"}
                                        className="industry"
                                    />
                                </div>
                                <p className="explore-btn">
                                    CMJ INDUSTRIES is BIG BRAND PUNE MAHARASHTRA BASED Since 2019. (Cello Tape means CMJ
                                    INDUSTRIES)
                                </p>
                                <div className="cmj-description">
                                    <p className="text-p main-text">
                                        Dear Customer Welcome <span className="main">To CMJ INDUSTRIES</span>
                                    </p>
                                    <p className="text-p main-text">All range Available.</p>
                                    <p className="text-p  main">Transparent Tape Available, Brown Tape Available.</p>
                                    <p className="text-p main-text">
                                        {" "}
                                        Inch, 2 Inch and 3 Inch Size Available always in stock.
                                    </p>
                                    <p className="text-p blue">
                                        You will get here ACCURATE LENGTH. We never comprise with Length or Quality. CMJ
                                        INDUSTRIES IS BIG BRAND. for more details you can visit our BRAND STORE CMJ
                                        INDUSTRIES. 30 MTR, 40 MTR, 65 MTR, 100 MTR Length always in stock.
                                    </p>
                                    <p className="text-p italic">
                                        Keep In mind Always our business slogan. Choose Best- Stick Best, First use then
                                        Trust. Thanks & Warm Regards.
                                    </p>
                                </div>
                            </div>

                            <div className="transparent-tape">
                                <p className="explore-btn">Transparent Tape</p>
                                <div className="industry-main">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assests/ProductDetails/2.png"}
                                        className="industry"
                                    />
                                </div>

                                <p className="explore-btn mt-5">Brown Tape</p>
                                <div className="industry-main">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assests/ProductDetails/5.png"}
                                        className="industry"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ProductDescription;
