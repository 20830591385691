import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./PdfMain.css"
import Button_Common from "../../Button_Common/Button_Common"
const PdfMain = (props) => {
    return (
        <>
            <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="Main-Pdf">
              
                <Modal.Body>
                    <img src={process.env.PUBLIC_URL + "/assests/Checkout/Image.png"} className="pdf" />
                </Modal.Body>
                <Modal.Footer>
                <Button_Common text="Cancel" className="cancel small-text" onClick={props.onHide}/>
                <Button_Common text="Download" className="orange small-text"/>
                    {/* <Button >Close</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PdfMain;
