import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./AddedCard.css"
import LogOut from "../Common_Modal/LogOut/LogOut";

const AddedCard = ({image, name, text, quantity, price, isSelected, onSelect}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [count, setCount] = useState(0);

    const increaseCount = () => {
        setCount((prevCount) => prevCount + 1);
    };

    const decreaseCount = () => {
        setCount((prevCount) => Math.max(prevCount - 1, 0));
    };
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div className="Deatils-address">
            <Form.Check type="checkbox"  checked={isSelected} onChange={onSelect} />
            <div className="OrderDetails">
                <div className="Details">
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} xs={12} sm={12}>
                            <div className="main-cart">
                                <div className="image-product">
                                    <img src={image} alt="product" className="payment-logo" />
                                </div>
                            </div>
                        </Col>
                        <Col xxl={7} xl={8} lg={7} md={7} xs={12} sm={12}>
                            <div className="content">
                                <p className="commmontext">{name}</p>
                                <p className="small-text">{text}</p>
                                <div className="Quantity">
                                    <p className="small-text">QTY</p>
                                    {/* <div className="quantity-no sub-title">{quantity}</div> */}
                                    <span className="conutssdiv me-3">
                                        <FontAwesomeIcon
                                            icon={faChevronLeft}
                                            className="ficnn px-1"
                                            onClick={decreaseCount}
                                        />
                                        <span className="px-1">{count}</span>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className="ficnn px-1"
                                            onClick={increaseCount}
                                        />
                                    </span>
                                </div>
                                <p className="price commmontext">{price}</p>
                            </div>
                        </Col>
                        <Col xxl={2} xl={1} lg={2} md={1} xs={12} sm={12}>
                            <div className="Delete-order"   onClick={() => setModalShow(true)}>
                                <div className="delete-main">
                                    <FontAwesomeIcon icon={faTrash} className="icon" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <LogOut show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      
    );
};

export default AddedCard;
