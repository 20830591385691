import React, {useEffect, useRef, useState} from "react";
import "./ProductDetail.css";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import {Autoplay, Navigation, Pagination} from "swiper/modules";
function ProductDetail() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const productlist = [
        {
            text: "Use for packaging and everyday needs ",
        },
        {
            text: " Resistant to water and oil ",
        },
        {
            text: "Contractors grade for painting and construction industries",
        },
        {
            text: " Residue free",
        },
    ];

    const vertcalls = [
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png",
        },
    ];

    const [selectedImage, setSelectedImage] = useState(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    class Zoomable {
        constructor(element, config) {
            this.element = element;
            this.config = this._mergeConfig(config);

            const {initialZoom, minZoom, maxZoom} = this.config;

            this.zoomed = false;
            this.initialZoom = Math.max(Math.min(initialZoom, maxZoom), minZoom);
            this.zoom = this.initialZoom;

            this.img = element.querySelector(".zoomable__img");
            this.img.draggable = false;
            this.element.style.setProperty("--zoom", this.initialZoom);

            this._addEventListeners();
        }

        static get Default() {
            return Default;
        }

        _addEventListeners() {
            this.element.addEventListener("mouseenter", () => this._handleMouseover());
            this.element.addEventListener("mousemove", (evt) => this._handleMousemove(evt));
            this.element.addEventListener("mouseleave", () => this._handleMouseout());
            this.element.addEventListener("wheel", (evt) => this._handleWheel(evt));
        }

        _handleMouseover() {
            if (this.zoomed) {
                return;
            }

            this.element.classList.add("zoomable--zoomed");
            this.zoomed = true;
        }

        _handleMousemove(evt) {
            if (!this.zoomed) {
                return;
            }

            const elPos = this.element.getBoundingClientRect();
            const percentageX = `${((evt.clientX - elPos.left) * 100) / elPos.width}%`;
            const percentageY = `${((evt.clientY - elPos.top) * 100) / elPos.height}%`;

            this.element.style.setProperty("--zoom-pos-x", percentageX);
            this.element.style.setProperty("--zoom-pos-y", percentageY);
        }

        _handleMouseout() {
            if (!this.zoomed) {
                return;
            }

            this.element.style.setProperty("--zoom", this.initialZoom);
            this.element.classList.remove("zoomable--zoomed");
            this.zoomed = false;
        }

        _handleWheel(evt) {
            if (!this.zoomed) {
                return;
            }

            evt.preventDefault();

            const newZoom = this.zoom + evt.deltaY * (this.config.zoomSpeed * -1);
            const {minZoom, maxZoom} = this.config;

            this.zoom = Math.max(Math.min(newZoom, maxZoom), minZoom);
            this.element.style.setProperty("--zoom", this.zoom);
        }

        _mergeConfig(config) {
            return {
                ...this.constructor.Default,
                ...(typeof config === "object" ? config : {}),
            };
        }
    }

    const Default = {
        initialZoom: 3,
        minZoom: 1.25,
        maxZoom: 4,
        zoomSpeed: 0.01,
    };

    const zoomableRef = useRef(null);

    useEffect(() => {
        const element = zoomableRef.current;
        if (!element) return;

        const zoomable = new Zoomable(element);

        return () => {
            element.removeEventListener("mouseenter", zoomable._handleMouseover);
            element.removeEventListener("mousemove", zoomable._handleMousemove);
            element.removeEventListener("mouseleave", zoomable._handleMouseout);
            element.removeEventListener("wheel", zoomable._handleWheel);
        };
    }, []);

    const images = [
        {image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png"},
        {image: process.env.PUBLIC_URL + "/assests/Products/Tape.png"},
        {image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png"},
        {image: process.env.PUBLIC_URL + "/assests/Products/Tape.png"},
        {image: process.env.PUBLIC_URL + "/assests/Products/Tape2.png"},
    ];
    return (
        <>
            <section className="productdetail_section mb-4">
                <Container>
                    <div className="productdetail_main">
                        <Row>
                            <Col xxl={4} xl={5} lg={6} md={12}>
                                <div className="imggswiper">
                                    {/* <div>
                                        <Swiper
                                            direction={'vertical'}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Pagination]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>Slide 1</SwiperSlide>
                                            <SwiperSlide>Slide 2</SwiperSlide>
                                            <SwiperSlide>Slide 3</SwiperSlide>
                                            <SwiperSlide>Slide 4</SwiperSlide>

                                        </Swiper>
                                    </div> */}

                                    {/* <div className="silder-btn">
                                        <div className="back-btn" onClick={() => handleMainSliderClick("next")}>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </div>
                                        <div className="next-btn" onClick={() => handleMainSliderClick("prev")}>
                                            <FontAwesomeIcon icon={faAngleLeft} />
                                        </div>
                                    </div> */}

                                    <Row>
                                        <Col xxl={9} xl={9} lg={8} md={9} sm={9} xs={12} className="pe-md-0">
                                            <div className="imgmaindivv">
                                                <div className="product-detail text-center">
                                                    {selectedImage ? (
                                                        <div className="specs " ref={zoomableRef}>
                                                            <img
                                                                src={selectedImage}
                                                                className="zoomable__img"
                                                                alt="Selected"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="specs " ref={zoomableRef}>
                                                            <img
                                                                src={
                                                                    process.env.PUBLIC_URL +
                                                                    "/assests/Products/Tape2.png"
                                                                }
                                                                className="zoomable__img"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        {/* <Col xxl={3} xl={3} lg={4} md={12}>
                                            <div className='sswiping_div'>
                                                <Swiper
                                                    direction={'vertical'}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    slidesPerView={"auto"}
                                                    modules={[Pagination, Navigation, Autoplay]}
                                                    breakpoints={{
                                                        0: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 60,
                                                        },
                                                        380: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 10,
                                                        },
                                                        485: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 10,
                                                        },
                                                        575: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 30,
                                                        },

                                                        768: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 10,
                                                        },
                                                        992: {
                                                            slidesPerView: 6,
                                                            spaceBetween: 80,
                                                        },
                                                        1024: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 10,
                                                        },
                                                        1200: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 80,
                                                        },
                                                        1440: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 50,
                                                        },
                                                        2000: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 20,
                                                        },
                                                    }}


                                                    className="mySwiper"
                                                >
                                                    {vertcalls.map((item, index) => (
                                                        <SwiperSlide>
                                                            <div className='imgmaindivv'>


                                                                <div className='imgaggmain'>
                                                                    <img className='swipingimg' src={process.env.PUBLIC_URL + "/assests/Products/Tape2.png"} />
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}

                                                </Swiper>
                                            </div>
                                        </Col> */}
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={4} className="verticalSlider ">
                                            <div>
                                                <div className=" camera-slider">
                                                    {/* <div className="row p-0 m-0">
                                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"> */}
                                                    <div className="swipermmains">
                                                        <Swiper
                                                            slidesPerView={"auto"}
                                                            modules={[Autoplay]}
                                                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                            direction="vertical"
                                                            breakpoints={{
                                                                0: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 10,
                                                                },
                                                                370: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                485: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                575: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },

                                                                768: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                992: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                1024: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                1200: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                1440: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                            }}
                                                            pagination={{clickable: true}}
                                                            className="mySwiper"
                                                        >
                                                            {images?.map((slide, index) => (
                                                                <SwiperSlide key={index}>
                                                                    <div className="Main-div">
                                                                        <div
                                                                            className="camera-imgs"
                                                                            onClick={() =>
                                                                                handleImageClick(slide?.image)
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={slide?.image}
                                                                                className="inner-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))}
                                                        </Swiper>

                                                        <div
                                                            className="back-btn"
                                                            onClick={() => handleMainSliderClick("prev")}
                                                        >
                                                            <FontAwesomeIcon icon={faChevronUp} className="icon-main" />
                                                        </div>
                                                        <div
                                                            className="next-btn"
                                                            onClick={() => handleMainSliderClick("next")}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faChevronDown}
                                                                className="icon-main"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="horizantalSlider">
                                        <div className=" camera-slider">
                                            <div className="row p-0 m-0">
                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div className="horiswiper">
                                                        <Swiper
                                                            slidesPerView={"auto"}
                                                            modules={[Autoplay]}
                                                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                                                            breakpoints={{
                                                                0: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 10,
                                                                },
                                                                370: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                485: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 10,
                                                                },
                                                                575: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 30,
                                                                },

                                                                768: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 20,
                                                                },
                                                                992: {
                                                                    slidesPerView: 6,
                                                                    spaceBetween: 30,
                                                                },
                                                                1024: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 30,
                                                                },
                                                                1200: {
                                                                    slidesPerView: 4,
                                                                    spaceBetween: 30,
                                                                },
                                                                1440: {
                                                                    slidesPerView: 5,
                                                                    spaceBetween: 30,
                                                                },
                                                                2000: {
                                                                    slidesPerView: 5,
                                                                    spaceBetween: 20,
                                                                },
                                                            }}
                                                            pagination={{clickable: true}}
                                                            className="mySwiper"
                                                        >
                                                            {images?.map((slide, index) => (
                                                                <SwiperSlide key={index}>
                                                                    <div className="Main-div">
                                                                        <div
                                                                            className="camera-imgs"
                                                                            onClick={() =>
                                                                                handleImageClick(slide?.image)
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={slide?.image}
                                                                                className="inner-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))}
                                                        </Swiper>

                                                        <div
                                                            className="back-btn"
                                                            onClick={() => handleMainSliderClick("prev")}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faAngleRight}
                                                                className="icon-main"
                                                            />
                                                        </div>
                                                        <div
                                                            className="next-btn"
                                                            onClick={() => handleMainSliderClick("next")}
                                                        >
                                                            <FontAwesomeIcon icon={faAngleLeft} className="icon-main" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={8} xl={7} lg={6} md={12} className="my-auto">
                                <div className="imggswiper py-3 ps-2">
                                    <div className="text-end">
                                        <li className="instock">In Stock</li>
                                    </div>

                                    <p className="tapename ms-3">Transparent Tape</p>
                                    <div className="litagg_div">
                                        <ul>
                                            {productlist.map((item, index) => (
                                                <li className="detailtext pb-2">{item.text}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default ProductDetail;
