import React, {useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {Navigation} from "swiper/modules";
import "./Deals.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faAngleLeft} from "@fortawesome/free-solid-svg-icons";
function Deals() {
    const Deals = [
        {
            image: process.env.PUBLIC_URL + "/assests/HomePage/Deals/deal1.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/HomePage/Deals/deal2.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/HomePage/Deals/deal3.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/HomePage/Deals/deal1.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/HomePage/Deals/deal2.png",
        },
    ];
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    return (
        <>
            <section className="Deals">
                <div className="container">
                    <div className="Deals_swiper">
                        <Swiper
                            className="mySwiper"
                            pagination={true}
                            // modules={[Autoplay]}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                380: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                485: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                575: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },

                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                992: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                1440: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                                2000: {
                                    slidesPerView: 3,
                                    spaceBetween: 10,
                                },
                            }}
                        >
                            {Deals.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="deals_img_holder">
                                        <img src={item.image} className="deals_img " />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="silder-btn">
                            <div className="back-btn" onClick={() => handleMainSliderClick("prev")}>
                                <FontAwesomeIcon icon={ faAngleLeft} className="icon-main" />
                            </div>
                            <div className="next-btn" onClick={() => handleMainSliderClick("next")}>
                                <FontAwesomeIcon icon={faAngleRight} className="icon-main" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Deals;
