import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Modal from "react-bootstrap/Modal";
import "./QuickInquiry.css"
import Button_Common from "../../Button_Common/Button_Common"
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
const QuickInquiry = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    
    return (
        <>
            <section className="QuickInquiry">
                <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="QuickInquiry">
                 
                        <div className="Modal-holder">
                            <div className="log_in_form">
                                <div className="login-box-button">
                                    <p>Quick inquiry</p>
                                </div>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="sub-title">Name*</Form.Label>
                                        <Form.Control type="name" placeholder="name" />
                                    </Form.Group>

                                    <Row>
                                        <Col xxl={6} xl={6} lg={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="sub-title">Email*</Form.Label>
                                                <Form.Control type="email" placeholder="name@example.com" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="sub-title">Phone No.*</Form.Label>
                                                <Form.Control type="number" placeholder="Phone No" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="sub-title">Product Name*</Form.Label>
                                        <Form.Control type="name" placeholder="Product Name" />
                                    </Form.Group>
                                    <p>Subject*</p>
                                    <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{height: "100px"}}
                                        />
                                    </FloatingLabel>
                                </Form>
                                <div className="Button-Main mt-3">
                                
                                <Button_Common text="Cancel" className="cancel" />
                                <Button_Common text="Submit"  className="submit orange" onClick={() => setModalShow(true)}/>
                                </div>
                            </div>
                        </div>
                   
                </Modal>
                <Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message="Inquiry Send Successfully!"
      />
            </section>
        </>
    );
};

export default QuickInquiry;
