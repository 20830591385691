// import React from "react";
import "./HomeBanner.css";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper/modules";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

import React,{useEffect} from "react";
const HomeBanner = () => {
    const banners = [
        {
            image: process.env.PUBLIC_URL + "/assests/HomePage/Banner/home1.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/HomePage/Banner/home1.png",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/HomePage/Banner/home1.png",
        },
    ];
  
    return (
        <>
            <section className="HomeBanner">
                <Container>
                    <Row>
                        <Col xxl={8} xl={8} lg={8} className="">
                            <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                                {banners.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="Main-Banner">
                                            <img src={item.image} className="banner" />
                                            <div className="Button-Explore">
                                                <Button_Common
                                                    text="Explore Now"
                                                    icon={faArrowRight}
                                                    className="explore explore-btn"
                                                />
                                                {/* <Button variant="primary" className="explore explore-btn me-2">Explore Now <FontAwesomeIcon icon={faArrowRight} className="icon-arrow"/></Button> */}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Col>
                        <Col xxl={4} xl={4} lg={4}>
                            <div className="side-images">
                                <img
                                    src={process.env.PUBLIC_URL + "/assests/HomePage/Banner/home2.png"}
                                    className="banner2 "
                                />
                            </div>
                            <div className="side-images mt-4">
                                <img
                                    src={process.env.PUBLIC_URL + "/assests/HomePage/Banner/home3.png"}
                                    className="banner2"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default HomeBanner;
