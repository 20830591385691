import React, { useState, useEffect, useRef } from "react";
import { Form, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OTP_Modal.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function OTP_Modal() {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(Array(4).fill(""));
    const inputRefs = useRef([]);

    const handleClose = () => {
        navigate("/set-new-password");
    };

    const handleInput = (event, index) => {
        const { value } = event.target;
        if (/^\d*$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value !== "" && index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    useEffect(() => {
        if (otp.every((digit) => digit !== "")) {
            navigate("/set-new-password");
        }
    }, [otp, navigate]);

    return (
        <div className="Otp-main">
            <div className="LogIn">
                <Container>
                    <div className="row justify-content-center me-0 ms-0">
                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-7 ">
                            <div className="otp-main-start">
                                <div className="log_in_form">
                                    <div className="heading-holder sub-title">
                                        OTP has been Sent to the Email / Mobile Number
                                    </div>

                                    <Form>
                                        <div className="otp-holder">
                                            {[...Array(4)].map((_, index) => (
                                                <div className="otp-text-holder" key={index}>
                                                    <Form.Group className="mb-3" controlId={`otp-${index}`}>
                                                        <Form.Control
                                                            type="text"
                                                            maxLength={1}
                                                            value={otp[index]}
                                                            onChange={(e) => handleInput(e, index)}
                                                            ref={(el) => (inputRefs.current[index] = el)}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            ))}
                                        </div>
                                    </Form>
                                    <div className="otp-close">
                                        <div className="otp" onClick={handleClose}>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default OTP_Modal;
