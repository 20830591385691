import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import "./LogOut.css";
import Button_Common from "../../Button_Common/Button_Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import {Button} from "react-bootstrap";

const LogOut = (props) => {
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);

    const handleSubmit = () => {
        setSuccessfullModalShow(true); 
        props.onHide(); 
    };

    useEffect(() => {
        let timer;
        if (modalSuccessfullShow) {
          timer = setTimeout(() => {
            setSuccessfullModalShow(false);
          }, 2000);
        }
    
        return () => clearTimeout(timer); // Cleanup the timer
      }, [modalSuccessfullShow]); // Dependency array

    return (
        <>
            <section className="Log">
                <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered className="Log Log-out-modal">
                    <div className="Modal-holder Log ">
                        <Button variant="link" onClick={props.onHide}>
                            <FontAwesomeIcon icon={faXmark} />
                        </Button>
                        <div className="log_in_form">
                            <div className="login-box-button">
                                <p>Delete Product?</p>
                            </div>
                            <p className="footer-text">Do you want to</p>
                            <p className="footer-text log">Delete the Item?</p>
                            <div className="button-main">
                                <Button_Common className="small-text cancel" text="Cancel" onClick={props.onHide} />
                                <Button_Common
                                    className="small-text orange submit"
                                    text="Submit"
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>

            <Bottom_Successfull_Modal
                show={modalSuccessfullShow}
                onHide={() => setSuccessfullModalShow(false)}
                icon={faCircleCheck}
                text="Product is Deleted From the cart Successfully!"
            />
        </>
    );
};

export default LogOut;
