import React, { useEffect } from 'react'
import ProductInformation from './ProductInformation/ProductInformation'
import ProductDescription from './ProductDescription/ProductDescription'
import ProductSlider from './ProductSlider/ProductSlider'
import WhyUs from './WhyUs/WhyUs'
import AdhesiveQuality from './AdhesiveQuality/AdhesiveQuality'
import CustomerReviews from './CustomerReviews/CustomerReviews'
import Relevent_Product from './Relevent_Product/Relevent_Product'
const ProductDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return (
    <>
      <ProductSlider/>
      <ProductInformation/>
      <ProductDescription/>
      <WhyUs/>
      <AdhesiveQuality/>
      <CustomerReviews/>
      <Relevent_Product/>
    </>
  )
}

export default ProductDetailPage
