import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Breadcrumbs from "../../CommonComponent/Breadcrumbs/Breadcrumbs";
import AddedCard from "../../CommonComponent/AddedCard/AddedCard";
import "./CartComponent.css";
import Form from "react-bootstrap/Form";
import Button_Common from "../../CommonComponent/Button_Common/Button_Common";
import LogOut from "../../CommonComponent/Common_Modal/LogOut/LogOut";
import {Link} from "react-router-dom";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const CartComponent = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "Account", link: ""},
        {text: "Cart", link: "/cart", className: "active"},
    ]);

    const cart = [
        {
            image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
            name: "Brown Tape",
            text: "2 40 Meter,38 Micron, Per Box 72 Nos",
            quantity: "1",
            price: "₹.408",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
            name: "Brown Tape",
            text: "2 40 Meter,38 Micron, Per Box 72 Nos",
            quantity: "1",
            price: "₹.408",
        },
        // {
        //     image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
        //     name: "Brown Tape",
        //     text: "2 40 Meter,38 Micron, Per Box 72 Nos",
        //     quantity: "1",
        //     price: "₹.408",
        // },
        // {
        //     image: process.env.PUBLIC_URL + "/assests/ProductDetails/p1.png",
        //     name: "Brown Tape",
        //     text: "2 40 Meter,38 Micron, Per Box 72 Nos",
        //     quantity: "1",
        //     price: "₹.408",
        // },
    ];
    const [modalShow, setModalShow] = React.useState(false);

    const [selectedItems, setSelectedItems] = useState(cart.map(() => false));

    const handleSelectAll = (e) => {
        setSelectedItems(selectedItems.map(() => e.target.checked));
    };

    const handleSelectItem = (index) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.map((item, idx) => (idx === index ? !item : item))
        );
    };
    return (
        <>
            <section className="Cart">
                <Container>
                    <Breadcrumbs items={breadcrumbItems} />
                    <Row>
                        <Col xxl={9} xl={8} lg={12} md={12}>
                            <div className="delete-btn">
                                <Form>
                                    <Form.Check type="checkbox" label="Select All" className="small-text"   onChange={handleSelectAll}
                                        checked={selectedItems.every(Boolean)}/>
                                </Form>
                                <div className="main-circle"  onClick={() => setModalShow(true)}>
                                    <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-4">
                        <Col xxl={9} xl={8} lg={12} md={12}>
                            {cart?.map((item, index) => (
                                <div className="" key={index}>
                                    <AddedCard
                                        image={item.image}
                                        text={item.text}
                                        name={item.name}
                                        quantity={item.quantity}
                                        price={item.price}
                                        isSelected={selectedItems[index]}
                                        onSelect={() => handleSelectItem(index)}
                                    />
                                </div>
                            ))}
                        </Col>

                        <Col xxl={3} xl={4} lg={5} md={6}>
                            <div className="Order-Summary">
                                <h1 className="sub-title">Order Summary</h1>
                                <div className="Total t-main mt-4">
                                    <p className="sub-title">Total amount</p>
                                    <p className="sub-title">₹90</p>
                                </div>
                                <div className="Total  t-main mt-2">
                                    <p className="sub-title">Discount</p>
                                    <p className="sub-title">₹0</p>
                                </div>
                                <div className="Total  t-main mt-2">
                                    <p className="sub-title">Tax</p>
                                    <p className="sub-title">₹0</p>
                                </div>
                                <div className="Total mt-2">
                                    <p className="sub-title">Shipping</p>
                                    <p className="sub-title">₹0</p>
                                </div>
                                <div className="Total mt-4">
                                    <p className="sub-title t2">Total</p>
                                    <p className="sub-title t2">₹9760</p>
                                </div>
                                <Link to="/checkout">
                                    <div className="main-btn mt-4">
                                        <Button_Common className="check small-text" text="Proceed to Checkout" />
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <LogOut show={modalShow} onHide={() => setModalShow(false)} />
        </>
    );
};

export default CartComponent;
