import React from "react";
import "./Inquiry.css";
import { Container } from "react-bootstrap";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
import QuickInquiry from "../../CommonComponent/Common_Modal/QuickInquiry/QuickInquiry";
function Inquiry() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <section className="inquriy_section">
        <Container>
          <div className="inquiry_main">
            <div className="inquiry_text_div">
              <p className="lookingtext merriweather-bold">
                Are you looking for the perfect tape-bonding solution?
              </p>
              <p className="heretext mb-0">We're here to assist you</p>
            </div>
            <div className="inqbtndiv">
              <Common_Button
                onClick={() => setModalShow(true)}
                className="button button--webcoderskull button--border-thick button--text-medium explore-btn"
                text="Generate Quick Inquiry"
              />
            </div>
          </div>
        </Container>
        <QuickInquiry show={modalShow} onHide={() => setModalShow(false)} />
      </section>
    </>
  );
}

export default Inquiry;
