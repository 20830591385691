import React, {useEffect, useState} from "react";
import "./Login.css";
import {Col, Container, Form, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import Common_Button from "../../CommonComponent/Button_Common/Button_Common";
import Successfull_Modal from "../../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";

function Login(props) {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        if (modalShow) {
            const timer = setTimeout(() => {
                setModalShow(false);
                navigate("/");
            }, 2000); // Set to 2000 ms for 2 seconds
            return () => clearTimeout(timer);
        }
    }, [modalShow, navigate]);

    return (
        <>
            <div className="Modal-holder login-holder-main">
                <div className="LogIn">
                    <Container>
                        <div className="row bg-color me-0 ms-0 justify-content-center LogIn-holder">
                            <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-9">
                                <div className="row justify-content-center">
                                    <div className="col-xl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="log_in_form">
                                            <div className="main-close">
                                                <div className="close">
                                                    <FontAwesomeIcon icon={faXmark} />
                                                </div>
                                            </div>
                                            <div className="Main-starting">
                                                <div className="login-box-button">
                                                    <p>Login</p>
                                                    <b></b>
                                                </div>

                                                <Form>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Label className="small-text">
                                                            Email / Mobile Number
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="phone"
                                                            placeholder="Email / Mobile Number"
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className=" mb-3" controlId="">
                                                        <Form.Label className="small-text">Password</Form.Label>
                                                        <div className="password-field-holder">
                                                            <Form.Control
                                                                type={passwordVisible ? "text" : "password"}
                                                                placeholder="Password"
                                                            />
                                                            <div
                                                                className="eye-icon-holder"
                                                                onClick={togglePasswordVisibility}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="eye-icon"
                                                                    icon={passwordVisible ? faEye : faEyeSlash}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Link to={"/forgot-password"}>
                                                        <Form.Text className="Forgot_password sub">
                                                            Forgot password?
                                                        </Form.Text>
                                                    </Link>

                                                    <div className="btn-holder">
                                                        <Common_Button
                                                            onClick={() => setModalShow(true)}
                                                            className=" button button--webcoderskull button--border-thick button--text-medium login-btn sub-title"
                                                            text="Login"
                                                        />
                                                    </div>

                                                    <div className="create_account ">
                                                        <div className="login_text">
                                                            <p>
                                                                <Link to={"/register"}>
                                                                    <span className="sub-title">Create account?</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Form>
                                                <div className="log_in_with">
                                                    <p>or Login with social accounts</p>
                                                </div>

                                                <div className="social_img_holder">
                                                    <img
                                                        className="social_img"
                                                        src={process.env.PUBLIC_URL + "/assests/login/google-img.png"}
                                                        alt="social_img"
                                                    />
                                                </div>
                                                <Link to="/">
                                                    <div className="back_to_span">
                                                        <p>Back to Home {">"}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <Successfull_Modal show={modalShow} onHide={() => setModalShow(false)} message="Login Successfully!" />
        </>
    );
}

export default Login;
