import React from "react";
import "./SaleIsLive.css";
import {Container} from "react-bootstrap";
import Countdown from "react-countdown";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {Pagination} from "swiper/modules";
import ProductCard from "../../CommonComponent/ProductCard/ProductCard";
import CountUp from "react-countup";
import ProductCardSale from "../../CommonComponent/ProductCardSale/ProductCardSale";
function SaleIsLive() {
    const product = [
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Products/Tape.png",
            title: "Regular Transparent Tape",
            size: "Size: 24mm x 66m",
            saleprice: "₹ 1,234.00",
            realprice: "₹ 1,234.00",
        },
        
    ];
    return (
        <>
            <section className="salelive_section">
                <Container>
                    <div className="salelive_main_div">
                        <div className="toptext_div ">
                            <h2 className="title-main mb-3">
                               
                              <span className="main-title-border ">SALE IS LIVE</span>  ON
                            </h2>
                            <div className="coundown_div mb-3">
                                <div className="counter">
                                    <div className="timer">
                                        <CountUp start={0} end={0} duration={10} decimals={0} />
                                        <CountUp start={0} end={0} duration={10} decimals={0} />
                                    </div>
                                    <div className="timer dotts">
                                       :
                                    </div>
                                    <div className="timer">
                                        <CountUp start={0} end={0} duration={10} decimals={0} />
                                        <CountUp start={0} end={10} duration={10} decimals={0} />
                                    </div>
                                    <div className="timer dotts">
                                       :
                                    </div>
                                    <div className="timer">
                                        <CountUp start={0} end={0} duration={10} decimals={0} />
                                        <CountUp start={0} end={60} duration={10} decimals={0} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="swipe_div_main">
                            <Swiper
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    486: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 15,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 15,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 15,
                                    },
                                    1200: {
                                        slidesPerView: 3,
                                        spaceBetween: 15,
                                    },
                                    1400: {
                                        slidesPerView: 4,
                                        spaceBetween: 15,
                                    },
                                    1900: {
                                        slidesPerView: 4,
                                        spaceBetween: 15,
                                    },
                                }}
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                {product.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <ProductCardSale
                                            image={item.image}
                                            title={item.title}
                                            size={item.size}
                                            saleprice={item.saleprice}
                                            realprice={item.realprice}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default SaleIsLive;
