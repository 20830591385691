import React, {useEffect, useState} from "react";
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import "./ContactUs.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faEnvelope, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";
import Successfull_Modal from "../CommonComponent/Common_Modal/Successfull_Modal/Successfull_Modal";
import {useNavigate} from "react-router-dom";

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {text: "Home", link: "/"},
        {text: "Contact us", link: "", className: "active"},
    ]);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();

    const handleModalClose = () => {
        setModalShow(false);
        navigate('/');
    };

    const cardsDetails = [
        {
            title: "+91 3454564578",
            image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/truck.png",
            icon: faPhone,
        },
        {
            title: "Cmj@gmail.com",
            image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/technical-support.png",
            icon: faEnvelope,
        },
        {
            title: "Akshaya Nagar 1st Block,1st Cross, Patil Nagar, Pune",
            image: process.env.PUBLIC_URL + "/assests/HomePage/ChooseUs/locked-card.png",
            icon: faLocationDot,
        },
    ];

    return (
        <>
            <section className="ContactUs">
                <Container>
                    <Breadcrumbs items={breadcrumbItems} />
                    <div className="chooseus_main_div">
                        <Row className="justify-content-center">
                            {cardsDetails.map((item, index) => (
                                <Col key={index} xxl={3} xl={3} lg={4} md={4}>
                                    <div className="main">
                                        <div className="Step-choose">
                                            <h4 className="carddheading">{item.title}</h4>
                                            {/* <p className="carddtext sub-text">{item.text}</p> */}
                                            <div className="borderciclee"></div>
                                        </div>
                                        <div className="circles">
                                            <FontAwesomeIcon icon={item.icon} className="icon" />
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>

                    <div className="Map-Main">
                        <iframe
                            className="gmap_iframe"
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15133.39075304903!2d73.7810866!3d18.5131838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1719574302642!5m2!1sen!2sin"
                        ></iframe>
                        <div className="content-main">
                            <Row className="justify-content-center">
                                <Col xxl={6} xl={6} lg={6}>
                                    <div className="Contact-Us-Form">
                                        <Form>
                                            <Row>
                                                <Col xxl={6}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="email" placeholder="" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={6}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Email / Mobile Number</Form.Label>
                                                        <Form.Control type="email" placeholder="" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Subject</Form.Label>
                                                        <Form.Control type="email" placeholder="" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={12}>
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label>Message</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Leave a comment here"
                                                            style={{height: "100px"}}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <div className="submit">
                                                    <Button_Common
                                                        className="submit"
                                                        text="Submit"
                                                        onClick={() => setModalShow(true)}
                                                    />
                                                </div>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
                <Successfull_Modal show={modalShow} onHide={handleModalClose} message="Submit Successfully!" />
            </section>
        </>
    );
};

export default ContactUs;
