import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../CommonComponent/Breadcrumbs/Breadcrumbs';
import { Container } from 'react-bootstrap'

function ShippingPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Home", link: "/" },
        { text: "Shipping Policy", link: "" },
        ,
    ]);
  return (
    <>
            <section className='privacy_section'>
                <Container>
                    <div>
                        <Breadcrumbs items={breadcrumbItems} />
                    </div>
                    <div className='Privacy_main'>
                        <div className='privacybanner_div '>
                            <img className='privacybanner' src={process.env.PUBLIC_URL + "/assests/Banner/closecourier.png"} />
                            <div className='banoverlays'>


                                <p className='bannertitle'>Shipping Policy</p>
                            </div>

                        </div>
                        <div className='bannertext_main_div'>


                            <div className='bannertext_div mb-3'>
                                <p className='text-p '>At CMJ Industries, we ship our products through our trusted delivery partners based on the delivery location or pin code provided by our customers. We currently ship to over 24000+ pin codes across India.</p>
                           <div className='enjoytext_div'>
                            <p className='privcytext boldtext px-3'>"Enjoy hassle-free, Door-to-Door delivery with complimentary shipping, courtesy of CMJ Industries."</p>
                           </div>
                           
                            </div>

                            <div className='bannersubtext_div mb-3'>
                                <p className='privecy_subtitle'>01.Delivery Condition</p>
                                <p className='privcytext ms-sm-4'>We are committed to delivering your order quickly and in good condition. If your order does not reach you in good condition, please let us know by email and by WhatsApp with photos of the package and products. We assure you that we will help resolve the issue. <span className='boldtext'>PLEASE DO NOT ACCEPT ANY PACKAGE THAT IS NOT IN GOOD CONDITION</span>  (i.e., the outer package is torn, broken, or the seal is broken). If this is the case, we will resend your order or allow you to cancel and provide a refund.</p>

                                <div className='borderss my-3'></div>

                                <p className='privecy_subtitle'>02.Shipping Area</p>
                                <p className='privcytext ms-sm-4'>Currently, we ship to addresses only in India. If you do not receive your order within the given time frame, please contact us at cmjindustries13@gmail.com</p>
                               

                                <div className='borderss my-3'></div>

                                <p className='privecy_subtitle'>03.Order Processing and Dispatch</p>
                                <p className='privcytext ms-sm-4'>Once your order is placed and payment is verified, it typically takes 2 business days for our team to prepare your order for dispatch. After dispatch, you will receive an email and WhatsApp Notification with the tracking code for your order. You can also find this information under your account page in the order section.</p>
                              

                                <div className='borderss my-3'></div>

                                <p className='privecy_subtitle'>04.Delivery Time</p>
                                <p className='privcytext ms-sm-4'>The delivery time depends on your location. It usually takes approximately 3-7 days for your order to reach you after dispatch. In rare cases, delivery may take a bit longer due to unforeseen circumstances such as bad weather, festivals, or other situations beyond our control. CMJ Industries is not liable for such delays.</p>
                                


                                <h1 className='bigtextt'>Shipping Policy</h1>




                            </div>

                        </div>
                    </div>
                </Container>
            </section>

        </>
  )
}

export default ShippingPolicy